import React from 'react';

// Props:
type LookupFieldProps = {
    lookup: Array<{label: string, value: any}>;
    value: any;
};

// Element:
const LookupField : React.FunctionComponent<LookupFieldProps> = ({lookup, value}) => {
    // Consts:
    const currentValue = React.useMemo(() => {
        if (value)
        {
            var index = lookup.findIndex(x => x.value === value);

            if (index !== -1)
            {
                return lookup[index].label;
            }
        }

        return '<->';
    }, [value, lookup]);

    return (
        <>
            {currentValue}
        </>
    );
}

export default LookupField;