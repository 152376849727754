import React from 'react';
import { EntitySet, OdataContext } from 'ts2odata';
import * as dataTypes from 'schema';

export default class DataContext extends OdataContext<DataContext> {
    Accounts = EntitySet.default<dataTypes.HiddenAccount>();

    Actuals = EntitySet.default<dataTypes.Actual>();
    ActualTags = EntitySet.default<dataTypes.ActualTag>();
    
    Blogs = EntitySet.default<dataTypes.Blog>();
    BlogTags = EntitySet.default<dataTypes.BlogTag>();
    
    Carts = EntitySet.default<dataTypes.Cart>();
    CartPositions = EntitySet.default<dataTypes.CartPosition>();

    Categories = EntitySet.default<dataTypes.Category>();
    Orders = EntitySet.default<dataTypes.Order>();

    Products = EntitySet.default<dataTypes.Product>();
    ProductMedias = EntitySet.default<dataTypes.ProductMedia>();
    ProductPrices = EntitySet.default<dataTypes.ProductPrices>();

    Sections = EntitySet.default<dataTypes.Section>();
    SectionPrices = EntitySet.default<dataTypes.SectionPrices>();

    Slides = EntitySet.default<dataTypes.Slider>();

    TextBlocks = EntitySet.default<dataTypes.TextBlock>();

    UserInfos = EntitySet.default<dataTypes.UserInfo>();
    UserCompanies = EntitySet.default<dataTypes.UserCompany>();
    UserShipmentAddresses = EntitySet.default<dataTypes.UserShipmentAddress>();
}

export const useOdataContext = () => {
    var context = React.useMemo(() => DataContext, []);

    return context;
}