import React from 'react';
import DataTable from 'components/data_table';
import { usePagedModels } from 'hex/hooks/data';
import DataContext, { useOdataContext } from 'odata/context';


import columns, {filters, modifiers} from 'model_data/blog_tag';
import { useOrder } from 'hex/hooks/models';
import { useDataFilters } from 'components/data_filters/hook';
import DataFilters from 'components/data_filters';
import { useDataModifiers } from 'components/data_modifiers/hooks';
import DataModifiers from 'components/data_modifiers';

// Props:
type BlogTagsPageProps = {

};

// Element:
const BlogTagsPage : React.FunctionComponent<BlogTagsPageProps> = () => {
    // Data Context:
    var context = useOdataContext();

    // Query:
    const queryCallback = React.useCallback((context: DataContext) => context.BlogTags, []);
    
    // Order:
    const orderData = useOrder(columns[0].field);

    // Filters:
    const filtersData = useDataFilters(filters, queryCallback);

    // Models:
    const [, sections, pagination, refresh] = usePagedModels(context, filtersData.resultQuery, orderData);

    // Modifiers:
    const modifiersData = useDataModifiers({...modifiers, refresh});

    // Loading:
    if (sections === null)
    {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
    
    return (
        <>
            <h1>Тэги блога</h1>

            <DataFilters filtersData={filtersData} />
            <DataTable models={sections} columns={columns} orderData={orderData} pagination={pagination} 
                       actions={modifiersData.actions} globalActions={modifiersData.globalActions} selectedActions={modifiersData.selectedActions} />
            <DataModifiers modifiersData={modifiersData} />
        </>
    );
}

export default BlogTagsPage;