import moment from 'moment-timezone';
import { Column, ModelType } from './types';
import 'moment/locale/ru';
import React from 'react';
import ImageField from './fields/ImageField';
import LookupField from './fields/lookupField';


const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


export function renderField<TModel extends ModelType>(column: Column<TModel>, data: any) {
    switch (column.type) {
        case 'bool':
            return data === true ? 'Да' : 'Нет';
        case 'date':
            return moment.utc(data).tz(clientTimeZone).format("DD-MM-YYYY");
        case 'datetime':
            return moment.utc(data).tz(clientTimeZone).format("DD-MM-YYYY HH:mm");
        case 'image':
            return <ImageField path={data} />
        case 'lookup':
            return <LookupField value={data} lookup={column.lookup} />
        case 'fk':
            return data[column.fk_field];
        default:
            return data;
    }
}