import React from 'react';
import { TextareaAutosize } from '@material-ui/core';

// Props:
type BigTextProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
};

// Element:
const BigText : React.FunctionComponent<BigTextProps> = ({label, value, onChange}) => {
    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    return (
        <TextareaAutosize rowsMin={5} placeholder={label} value={value || ""} onChange={onChangeEventWrapper} style={{width: '100%', marginTop: '10px'}} />
    );
}

export default BigText;