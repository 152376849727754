import React from 'react';
import TextField from '@material-ui/core/TextField';

// Props:
type NumberFieldProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
};

// Element:
const NumberField : React.FunctionComponent<NumberFieldProps> = ({label, value, onChange}) => {
    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(parseInt(event.target.value, 10));
    };

    return (
        <TextField margin="dense" label={label} type="number" fullWidth onChange={onChangeEventWrapper} value={value} />
    );
}

export default NumberField;