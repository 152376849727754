import { Avatar } from '@material-ui/core';
import { BASE_SERVER_PATH, STATIC_PATH } from 'config/server';
import React from 'react';

// Props:
type ImageFieldProps = {
    path?: string;
};

// Element:
const ImageField : React.FunctionComponent<ImageFieldProps> = ({path}) => {
    return (
        <Avatar alt="Image" src={path ? BASE_SERVER_PATH + STATIC_PATH + path : undefined}  />
    );
}

export default ImageField;