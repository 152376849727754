import HttpClient from "hex/http/client";
import { processHttpError } from "hex/utils/errors";

type ModelType = {
    ID: string;
}

const ApiNew = (client: HttpClient, modelName: string, model: ModelType) => {
    return new Promise((resolve, reject) => {
        client.post(`/api/models/${modelName}`, model)
        .then((data) => {
            resolve(data);
        })
        .catch((reason) => {
            processHttpError(reason);
            reject(reason);
        });
    });
}

export default ApiNew;