import { FilterInfo, createQueryCallback } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column } from "components/data_table/types";
import DataContext from "odata/context";
import React from "react";
import { Actual, ActualTag, Product } from "schema";

import ProductFkViewProps from './views/productFkView';



const columns : Array<Column<Actual, Product>> = [
    {
        type: 'fk',
        field: 'Product',
        fk_field: 'Name',
        name: 'Товар'
    },
    {
        type: 'number',
        name: 'Сортировка',
        field: 'Sort',
        sort: true,
    },
    {
        type: 'fk',
        field: 'Tag',
        fk_field: 'Name',
        name: 'Тэг'
    },
    {
        type: 'datetime',
        name: 'Дата изменения',
        field: 'Updated',
        sort: true,
    },
    {
        type: 'datetime',
        name: 'Дата создания',
        field: 'Created',
        sort: true,
    }
];

export const filters: Array<FilterInfo<DataContext, Actual>> = [

];

export const modifiers : DataModifiersArgs<Actual> = {
    modelName: 'Actuals',
    deleteData: {
        text: (model) => `Вы действительно хотите удалить актуальное предложение?`,
    },
    newData: {
        pages: [
            {
                label: 'Информация о разделе',
                columns: [
                    {
                        type: 'fk-custom-view',
                        field: 'ProductId',
                        name: 'Товар',
                        view: (props : {value: any, onChange: (value: any) => void}) => <ProductFkViewProps {...props} />,
                    },
                    {
                        type: 'fk',
                        name: 'Тэг',
                        field: 'TagId',
                        getName: ((model: ActualTag) => model.Name) as (model: ActualTag) => string,
                        query: (context: DataContext) => context.ActualTags
                    },
                    {
                        type: 'number',
                        name: 'Сортировка',
                        field: 'Sort',
                        sort: true,
                    },
                ],
            },
        ],
        defaultModel: {
            ID: undefined as any,
            Sort: 0,
            ProductId: "",
            TagId: "",
        }
    },
    updateData: {
        pages: [
            {
                label: 'Информация о разделе',
                columns: [
                    {
                        type: 'fk-custom-view',
                        field: 'ProductId',
                        name: 'Товар',
                        view: (props : {value: any, onChange: (value: any) => void}) => <ProductFkViewProps {...props} />,
                    },
                    {
                        type: 'fk',
                        name: 'Тэг',
                        field: 'TagId',
                        getName: ((model: ActualTag) => model.Name) as (model: ActualTag) => string,
                        query: (context: DataContext) => context.ActualTags
                    },
                    {
                        type: 'number',
                        name: 'Сортировка',
                        field: 'Sort',
                        sort: true,
                    },
                ],
            },
        ],
    },
}

export default columns;