import React from 'react';
import { Redirect } from "react-router-dom";

import {useAuthState} from 'hex/hooks/auth';


// Props:
type AuthGateProps = {
    
};

const AuthGate : React.FunctionComponent<AuthGateProps> = ({children}) => {
    // Auth State:
    const signedIn = useAuthState();

    if (signedIn === null)
    {
        return (
            <div>LOADING...</div>
        );
    }
    return (
        <>
            {signedIn ? children : <Redirect to="/login" />}
        </>
    );
};

export default AuthGate;