import React from 'react';
import DataFilters from 'components/data_filters';
import { useDataFilters } from 'components/data_filters/hook';
import DataModifiers from 'components/data_modifiers';
import { useDataModifiers } from 'components/data_modifiers/hooks';
import DataTable from 'components/data_table';
import { usePagedModels } from 'hex/hooks/data';
import { useOrder } from 'hex/hooks/models';
import columns, { filters, modifiers, viewer } from 'model_data/order';
import DataContext, { useOdataContext } from 'odata/context';
import DataViewer from 'components/data_viewer';
import { useViewer } from 'components/data_viewer/hooks';

// Props:
type OrdersPageProps = {

};

// Element:
const OrdersPage : React.FunctionComponent<OrdersPageProps> = () => {
    // Data Context:
    var context = useOdataContext();

    // Query:
    const queryCallback = React.useCallback((context: DataContext) => context.Orders.filter(x => x.CartId != null).expand(x => x.Account)
                                                                        .expand(x => x.Cart).thenExpand(x => x.CartPositions).thenExpand(x => x.Product), []);
    
    // Order:
    const orderData = useOrder(columns[0].field);

    // Filters:
    const filtersData = useDataFilters(filters, queryCallback);

    // Models:
    const [, sections, pagination, refresh] = usePagedModels(context, filtersData.resultQuery, orderData);

    // Modifiers:
    const modifiersData = useDataModifiers({...modifiers, refresh});

    // Viewer:
    const [viewerData, newModifiersData] = useViewer(viewer, modifiersData);

    // Loading:
    if (sections === null)
    {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
    
    return (
        <>
            <h1>Заказы</h1>

            <DataFilters filtersData={filtersData} />
            <DataTable models={sections} columns={columns} orderData={orderData} pagination={pagination} 
                       actions={newModifiersData.actions} globalActions={newModifiersData.globalActions} selectedActions={newModifiersData.selectedActions} />
            <DataModifiers modifiersData={newModifiersData} />
            <DataViewer data={viewerData} />
        </>
    );
}

export default OrdersPage;