import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Props:
type ActualSidebarProps = {

};

// Element:
const ActualSidebar : React.FunctionComponent<ActualSidebarProps> = () => {
    const history = useHistory();

    return (
        <List>
            <ListItem button key={"slides"} onClick={() => history.push('/site/slides/')}>
                <ListItemText color='primary' primary='Слайды' />
            </ListItem>
            <ListItem button key={"blogtags"} onClick={() => history.push('/site/blog-tags/')}>
                <ListItemText color='primary' primary='Тэги блога' />
            </ListItem>
            <ListItem button key={"blogs"} onClick={() => history.push('/site/blogs/')}>
                <ListItemText color='primary' primary='Блог' />
            </ListItem>
            <ListItem button key={"blogs"} onClick={() => history.push('/site/actual-tags/')}>
                <ListItemText color='primary' primary='Тэги актуальных предложений' />
            </ListItem>
            <ListItem button key={"blogs"} disabled>
                <ListItemText color='primary' primary='Актуальные предложения' />
            </ListItem>
        </List>
    );
}

export default ActualSidebar;