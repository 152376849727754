import React from "react";
import { FilterInfo, createQueryCallback } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column } from "components/data_table/types";
import { DataViewerModelInfo } from "components/data_viewer/types";
import DataContext from "odata/context";
import { Order, Account, EPaymentType } from "schema";
import OrderView from "./views/orderView";


const columns : Array<Column<Order, Account>> = [
    {
        type: 'string',
        name: 'Номер заказа',
        field: 'Number',
        sort: true,
    },
    {
        type: 'fk',
        name: 'ФИО',
        field: 'Account',
        fk_field: 'Name',
    },
    {
        type: 'datetime',
        name: 'Дата создания',
        field: 'Created',
        sort: true,
    },
    {
        type: 'lookup',
        name: 'Тип оплаты',
        field: 'PaymentType',
        sort: true,
        lookup: [
            {
                label: 'Счёт',
                value: EPaymentType.Bill,
            },
            {
                label: 'Картой на сайте',
                value: EPaymentType.Card,
            },
            {
                label: 'Наличными',
                value: EPaymentType.Cash,
            }
        ]
    },
    {
        type: 'string',
        name: 'Тип доставки',
        field: 'ShipmentType',
        sort: true,
    },
    {
        type: 'bool',
        name: 'Оплачено на сайте',
        field: 'WasPayed',
        sort: true,
    },
    {
        type: 'bool',
        name: 'Передано в 1С',
        field: 'InSystem',
        sort: true,
    },
];

export const filters: Array<FilterInfo<DataContext, Order>> = [
    {
        type: 'text',
        label: 'Номер заказа',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Number.includes(value), { value: value })),
    },
];

export const modifiers : DataModifiersArgs<Order> =
{
    modelName: 'Orders',
    /*deleteData: {
        text: (model) => `Вы действительно хотите удалить заказ "${model.Number}"?`,
    },*/
}

export const viewer: DataViewerModelInfo<Order> = {
    componentToRender: (model) => {
        return <OrderView model={model} />
    }
}

export default columns;