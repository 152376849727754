import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './htmltext.css';

// Element:
const HTMLTextField = ({label, value, onChange}) => {
    return (
        <CKEditor
            editor={ ClassicEditor }
            onReady={ editor => {
                // You can store the "editor" and use when it is needed.
                console.log( 'Editor is ready to use!', editor );
            } }
            data={value}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                onChange(data);
            } }
            onBlur={ ( event, editor ) => {
                
            } }
            onFocus={ ( event, editor ) => {
                
            } }
        />
    );
}

export default HTMLTextField;