import { FilterInfo, createQueryCallback } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column } from "components/data_table/types";
import DataContext from "odata/context";
import { EAccountRole, HiddenAccount, UserCompany, UserInfo } from "schema";

import UserCompanyColumns, {modifiers as UserCompanyModifiers, defaultModel as UserCompanyDefaultModel} from './userCompany';


const columns : Array<Column<HiddenAccount>> = [
    {
        type: 'string',
        name: 'ФИО',
        field: 'Name',
        sort: true,
    },
    {
        type: 'string',
        name: 'Почта',
        field: 'UserName',
        sort: true,
    },
    {
        type: 'lookup',
        name: 'Роль',
        field: 'Role',
        sort: true,
        lookup: [
            {
                label: 'Клиент',
                value: EAccountRole.Customer,
            },
            {
                label: 'Сотрудник',
                value: EAccountRole.Administrator,
            },
            {
                label: 'Тестовый клиент',
                value: EAccountRole.TestCustomer,
            }
        ]
    },
    {
        type: 'bool',
        name: 'Активен',
        field: 'IsActive',
        sort: true,
    },
    {
        type: 'bool',
        name: 'Имеет доступ в админку',
        field: 'IsSuperuser',
        sort: true,
    },
];

export const filters: Array<FilterInfo<DataContext, HiddenAccount>> = [
    {
        type: 'text',
        label: 'ФИО',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Name.includes(value), { value: value })),
    },
    {
        type: 'bool',
        label: 'Активен',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.IsActive === value, { value: value })),
    },
];

export const modifiers : DataModifiersArgs<HiddenAccount> =
{
    modelName: 'Accounts',
    deleteData: {
        text: (model) => `Вы действительно хотите удалить пользователя "${model.Name}"?`,
    },
    newData: {
        pages: [
            {
                label: 'Информация',
                columns: [
                    {
                        type: 'string',
                        name: 'ФИО',
                        field: 'Name',
                    },
                    {
                        type: 'string',
                        name: 'Почта',
                        field: 'UserName',
                    },
                    {
                        type: 'string',
                        name: 'Пароль',
                        field: 'Password',
                    },
                    {
                        type: 'lookup',
                        name: 'Роль',
                        field: 'Role',
                        lookup: [
                            {
                                label: 'Клиент',
                                value: EAccountRole.Customer,
                            },
                            {
                                label: 'Сотрудник',
                                value: EAccountRole.Administrator,
                            },
                            {
                                label: 'Тестовый клиент',
                                value: EAccountRole.TestCustomer,
                            }
                        ]
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Город',
                        column: {
                            type: 'string',
                            name: 'Город',
                            field: 'City',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Адрес',
                        column: {
                            type: 'string',
                            name: 'Адрес',
                            field: 'Address',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Телефон',
                        column: {
                            type: 'string',
                            name: 'Телефон',
                            field: 'Phone',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Идентификатор контрагента в 1С',
                        column: {
                            type: 'string',
                            name: 'Идентификатор контрагента в 1С',
                            field: 'ExtSystemId',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Комментарий',
                        column: {
                            type: 'string',
                            name: 'Комментарий',
                            field: 'Comment',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'bool',
                        name: 'Активен',
                        field: 'IsActive',
                    },
                    {
                        type: 'bool',
                        name: 'Имеет доступ в админку',
                        field: 'IsSuperuser',
                    },
                ]
            },
            {
                label: 'Компании',
                columns: [
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Компании',
                        column: {
                            type: 'reverse_fk',
                            name: 'Компании',
                            field: 'UserCompanies',
                            columns: UserCompanyColumns as Column<any>[],
                            defaultModel: UserCompanyDefaultModel,
                            beforeCreate: (relatedModel: UserCompany, allModels: UserCompany[]) => {
                                return relatedModel;
                            },
                        } as Column<UserInfo>,
                    },
                ]
            }
        ],
        defaultModel: {
            ID: undefined as any,
            IsActive: false,
            IsSuperuser: false,
            Role: EAccountRole.Customer,
            UserInfo: {
                ID: undefined as any,
                AccountId: undefined as any,
                Address: "",
                City: "",
                Comment: "",
                Phone: "",
            }
        }
    },
    updateData: {
        pages: [
            {
                label: 'Информация',
                columns: [
                    {
                        type: 'string',
                        name: 'ФИО',
                        field: 'Name',
                    },
                    {
                        type: 'string',
                        name: 'Почта',
                        field: 'UserName',
                    },
                    {
                        type: 'string',
                        name: 'Пароль',
                        field: 'Password',
                    },
                    {
                        type: 'lookup',
                        name: 'Роль',
                        field: 'Role',
                        lookup: [
                            {
                                label: 'Клиент',
                                value: EAccountRole.Customer,
                            },
                            {
                                label: 'Сотрудник',
                                value: EAccountRole.Administrator,
                            },
                            {
                                label: 'Тестовый клиент',
                                value: EAccountRole.TestCustomer,
                            }
                        ]
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Город',
                        column: {
                            type: 'string',
                            name: 'Город',
                            field: 'City',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Адрес',
                        column: {
                            type: 'string',
                            name: 'Адрес',
                            field: 'Address',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Телефон',
                        column: {
                            type: 'string',
                            name: 'Телефон',
                            field: 'Phone',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Идентификатор контрагента в 1С',
                        column: {
                            type: 'string',
                            name: 'Идентификатор контрагента в 1С',
                            field: 'ExtSystemId',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Комментарий',
                        column: {
                            type: 'string',
                            name: 'Комментарий',
                            field: 'Comment',
                            sort: false,
                        } as Column<UserInfo>,
                    },
                    {
                        type: 'bool',
                        name: 'Активен',
                        field: 'IsActive',
                    },
                    {
                        type: 'bool',
                        name: 'Имеет доступ в админку',
                        field: 'IsSuperuser',
                    },
                ]
            },
            {
                label: 'Компании',
                columns: [
                    {
                        type: 'related_field',
                        field: 'UserInfo',
                        name: 'Компании',
                        column: {
                            type: 'reverse_fk',
                            name: 'Компании',
                            field: 'UserCompanies',
                            columns: UserCompanyColumns as Column<any>[],
                            defaultModel: UserCompanyDefaultModel,
                            beforeCreate: (relatedModel: UserCompany, allModels: UserCompany[]) => {
                                return relatedModel;
                            },
                        } as Column<UserInfo>,
                    },
                ]
            }
        ],
    },
}

export default columns;