import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Auth from 'hex/auth';
import HexAuthProvider from 'hex/providers';
import {BASE_SERVER_PATH} from 'config/server';
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import AuthGate from 'components/authGate';
import LoginPage from 'pages/login';

import Layout from 'components/layout';
import { ThemeProvider } from '@material-ui/core';

import {mainTheme} from 'config/theme';
import { getPagesRoutes, getSideBarRoutes } from 'routes';


// Props:
type HexAppProps = {
    
};

const HexApp : React.FunctionComponent<HexAppProps> = ({children}) => {
    // Consts:
    const auth = React.useMemo(() => new Auth(BASE_SERVER_PATH), []);

    // Sidebar:
    const sidebar = () => {
        return (
            <>
                <Switch>
                    {getSideBarRoutes().map((route, index) => {
                        var Component = route.component;

                        return (
                            <Route key={"route_" + index} path={ route.path } exact={true} render={props => (<Component {...props} /> )} />
                        )
                    })}
                </Switch>
            </>
        );
    }

    return (
        <HexAuthProvider auth={auth}>
            <ThemeProvider theme={mainTheme}>
                <NotificationContainer />
                <Router>
                    <Layout sidebar={sidebar()}>
                        <Switch>
                            <Route exact path="/login">
                                <LoginPage />
                            </Route>
                            <AuthGate>
                                {getPagesRoutes().map((object, index) => {
                                    var Component = object.component;

                                    return (
                                        <Route key={"route_" + index} path={ object.path } exact={true} render={props => (<Component {...props} /> )} />
                                    )
                                })}
                            </AuthGate>
                        </Switch>
                    </Layout>
                </Router>
            </ThemeProvider>
        </HexAuthProvider>
    );
};

export default HexApp;