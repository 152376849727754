import HttpClient from "hex/http/client";
import { processHttpError } from "hex/utils/errors";


const ApiFileUpload = (client: HttpClient, bucket: string, imageFile?: any) => {
    return new Promise((resolve, reject) => {
        const localVarFormParams = new FormData();
        if (imageFile !== undefined) {
            localVarFormParams.append('file', imageFile);

            client.postFile(`/media/${bucket}`, localVarFormParams)
            .then((data) => {
                resolve(data);
            })
            .catch((reason) => {
                processHttpError(reason);
                reject(reason);
            });
        }
    });
}

export default ApiFileUpload;