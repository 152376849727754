import React from 'react';
import TextField from '@material-ui/core/TextField';

// Props:
type DecimalFieldProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
};

// Element:
const DecimalField : React.FunctionComponent<DecimalFieldProps> = ({label, value, onChange}) => {
    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(parseFloat(event.target.value));
    };

    return (
        <TextField margin="dense" label={label} type="number" fullWidth onChange={onChangeEventWrapper} value={value} inputProps={{ min: "0", step: "0.01", pattern: '[0-9]+([\\.,][0-9]+)?', lang: "en" }} />
    );
}

export default DecimalField;