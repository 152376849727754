import { List, ListItem, ListItemText } from '@material-ui/core';
import { useModels } from 'hex/hooks/data';
import DataContext, { useOdataContext } from 'odata/context';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Props:
type CatalogSidebarProps = {

};

// Element:
const CatalogSidebar : React.FunctionComponent<CatalogSidebarProps> = () => {
    // Data Context:
    var context = useOdataContext();

    // Query:
    const queryCallback = React.useCallback((context: DataContext) => context.Sections, []);

    // Models:
    const [, sections] = useModels(context, queryCallback);

    // History:
    const history = useHistory();

    if (sections === null)
    {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
    
    return (
        <List>
            {sections.map((section, index) => (
                <ListItem button key={"section_" + index} onClick={() => history.push(`/catalog/${section.ID}/`)}>
                    <ListItemText primary={section.Name} />
                </ListItem>
            ))}
        </List>
    );
}

export default CatalogSidebar;