// Описываем работы кэша локального хранилища
export default class BrowserCache
{
    // Ключ хранилища
    private _storageName : string;

    // Конструктор
    public constructor(storageName : string)
    {
        this._storageName = storageName;
    }

    // Получить значение
    public getValue() : any
    {
        var result = localStorage.getItem(this._storageName);
        if (!result)
        {
            return undefined;
        }

        return JSON.parse(result);
    }

    // Установить значение
    public setValue(value: any)
    {
        if (value === undefined)
        {
            this.clearValue();
            return;
        }

        localStorage.setItem(this._storageName, JSON.stringify(value));
    }

    // Очистить значение
    public clearValue()
    {
        localStorage.removeItem(this._storageName);
    }
}