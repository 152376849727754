import React from "react";
import { Typography, IconButton, Tooltip } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import clsx from 'clsx';
import {useToolbarStyles} from './styles';
import { GlobalActionType } from "components/data_modifiers/types";


interface EnhancedTableToolbarProps {
  numSelected: number;
  globalActions?: Array<GlobalActionType>;
  selectedButtons?: React.ReactNode;
}


const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, globalActions, selectedButtons} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} выбрано
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Данные
        </Typography>
      )}
      {numSelected > 0 ? 
        <>
          {selectedButtons}
        </>
        :
        <>
          {globalActions && globalActions.map((action, index) => {
              return (
                <Tooltip title={action.label} key={'btn_' + index}>
                    <IconButton aria-label={action.label} onClick={action.action}>
                        {action.icon}
                    </IconButton>
                </Tooltip>
              );
          })}
        </>
      }
    </Toolbar>
  );
};

export default EnhancedTableToolbar;