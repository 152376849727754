import { FilterInfo } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column } from "components/data_table/types";
import DataContext from "odata/context";
import { ProductMedia } from "schema";


const columns : Array<Column<ProductMedia>> = [
    {
        type: 'image',
        name: 'Файл',
        field: 'Media',
        sort: false,
    },
];

export const filters: Array<FilterInfo<DataContext, ProductMedia>> = [];

export const defaultModel: ProductMedia = {
    ID: undefined as any,
    ProductId: undefined as any,
}

export const modifiers : DataModifiersArgs<ProductMedia> = {
    modelName: 'ProductMedias',
    deleteData: {
        text: (model) => `Вы действительно хотите удалить изображение?`,
    },
    newData: {
        pages: [
            {
                label: 'Информация',
                columns: [
                    {
                        type: 'image',
                        name: 'Файл',
                        field: 'Media',
                        sort: false,
                    },
                ],
            },
        ],
        defaultModel: {
            ID: undefined as any,
            ProductId: undefined as any,
        }
    },
}

export default columns;