import React from 'react';
import { EntitySet, OdataContext } from 'ts2odata';

import {BASE_SERVER_PATH} from 'config/server';
import { useAuth } from './auth';
import { usePagination } from './models';

const fixGuid = (text: string) => {
    var result = text;
    //SectionId+eq+'guid'89648c83-9eb2-459d-b7fe-bdac963234a0'' to SectionId+eq+89648c83-9eb2-459d-b7fe-bdac963234a0
    const index = text.indexOf('guid');
    if (index !== -1)
    {
        let part1 = text.substr(0, index - 1);
        let part2 = text.substr(index + 5, 36);
        let part3 = text.substr(index + 41 + 1 + 1);
        result = part1 + part2 + part3;
    }

    return result;
}


export function useModels<TContext extends OdataContext<TContext>, TModel extends object>(ctor: (new () => TContext) | (() => TContext), getQuery: (context: TContext) => EntitySet<TModel>) : [number, TModel[] | null] {
    // Auth:
    const auth = useAuth();

    // State:
    const [data, setData] = React.useState<TModel[] | null>(null);
    const [count, setCount] = React.useState<number>(0);

    React.useEffect(() => {
        // Context:
        let context: TContext = OdataContext.create(ctor, BASE_SERVER_PATH + '/api/models');

        // Query:
        let query = getQuery(context).getQueryUrl();

        // Add Count:
        query.searchParams.append('$count', 'true');

        //Fix filter:
        if (query.searchParams.has('$filter'))
        {
            var filters = query.searchParams.getAll('$filter');
            query.searchParams.delete('$filter');

            for (let index = 0; index < filters.length; index++) {
                const element = filters[index];
                query.searchParams.append('$filter', fixGuid(element));
            }
        }

        // Client:
        let client = auth.getHttpClient();

        // Get Request:
        client.get(query.pathname + query.search)
        .then((result: {value: TModel[], '@odata.count': number}) => {
            setData(result.value);
            setCount(result['@odata.count']);
        });
    }, [setData, auth, ctor, getQuery]);

    return [count, data];
}

type Order = 'asc' | 'desc';

type OrderData<TModel extends object> = {
    orderBy: keyof TModel;
    direction: Order;
}

export function usePagedModels<TContext extends OdataContext<TContext>, TModel extends object>(ctor: (new () => TContext) | (() => TContext), getQuery: (context: TContext) => EntitySet<TModel>, order?: OrderData<TModel>) 
        : [
            number, 
            TModel[] | null, 
            {
                options: Array<number>;
                count: number;
                rowsPerPage: number;
                page: number;
                handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
                handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
            },
            () => void
        ]
{
    // Auth:
    const auth = useAuth();

    // State:
    const [data, setData] = React.useState<TModel[] | null>(null);
    const [count, setCount] = React.useState<number>(0);

    // Pagination:
    const pagination = usePagination(count);

    // Consts:
    const orderBy = order ? order.orderBy : undefined; 
    const direction = order ? order.direction : undefined;

    // Callback:
    const refresh = React.useCallback(() => {
        // Context:
        let context: TContext = OdataContext.create(ctor, BASE_SERVER_PATH + '/api/models');

        // Query:
        let skip = pagination.page * pagination.rowsPerPage;
        let top = pagination.rowsPerPage;

        let query = getQuery(context);

        if (skip === 0)
        {
            query = query.top(top);
        }
        else
        {
            query = query.skip(skip).top(top);
        }

        let queryUri = query.getQueryUrl();
        
        // Add order:
        if ((orderBy !== undefined) && (direction !== undefined))
        {
            if (direction === 'asc')
            {
                queryUri.searchParams.append('$orderby', orderBy as string);
            }
            else
            {
                queryUri.searchParams.append('$orderby', (orderBy as string) + ' desc');
            }
        }

        // Add Count:
        queryUri.searchParams.append('$count', 'true');

        //Fix filter:
        if (queryUri.searchParams.has('$filter'))
        {
            var filters = queryUri.searchParams.getAll('$filter');
            queryUri.searchParams.delete('$filter');

            for (let index = 0; index < filters.length; index++) {
                const element = filters[index];
                queryUri.searchParams.append('$filter', fixGuid(element));
            }
        }

        // Client:
        let client = auth.getHttpClient();

        // Get Request:
        client.get(queryUri.pathname + queryUri.search)
        .then((result: {value: TModel[], '@odata.count': number}) => {
            setData(result.value);
            setCount(result['@odata.count']);
        });
    }, [setData, auth, ctor, getQuery, pagination.rowsPerPage, pagination.page, orderBy, direction]);

    React.useEffect(refresh, [refresh]);

    return [count, data, pagination, refresh];
}