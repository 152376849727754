import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Column as DataColumn } from 'components/data_modifiers/types';
import { renderField } from '.';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { AddIcon } from '@material-ui/data-grid';
import { RemoveCircleOutline } from '@material-ui/icons';


const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: '20px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerBtn: {
        marginLeft: 'auto',
    },
    container: {
        height: 440,
    },
});


// Props:
type ReverseFkProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    columns: DataColumn<any>[];
    beforeCreate?: (relatedModel: any, allModels: Array<any>) => any | null;
    defaultModel: any;
    initialModels?: () => Array<any>;
    filterModels?: (models: Array<any>) => Array<any>;
};

// Element:
const ReverseFk : React.FunctionComponent<ReverseFkProps> = ({label, onChange, value, columns, defaultModel, beforeCreate, initialModels, filterModels}) => {
    // Classes:
    const classes = useStyles();

    // States:
    const [models, setModels] = React.useState<Array<any>>(() => {
        if (initialModels === undefined)
        {
            return value || [];
        }
        else
        {
            if ((value as Array<any>).length !== 0) 
            {
                return value;
            }
            else
            {
                return initialModels();
            }
        }
    });

    // Events:
    const addEmptyModel = () => {
        if (beforeCreate)
        {
            const newModel = beforeCreate({...defaultModel}, models);

            if (newModel !== null)
            {
                setModels((oldModels) => {
                    let copy = [...oldModels];
                    copy.push(newModel);
                    onChange((filterModels === undefined) ? copy : filterModels(copy));
                    return copy;
                });
            }
        }
        else
        {
            setModels((oldModels) => {
                let copy = [...oldModels];
                copy.push({...defaultModel});
                onChange((filterModels === undefined) ? copy : filterModels(copy));
                return copy;
            });
        }
    }

    const removeModel = (index: number) => () => {
        setModels((oldModels) => {
            let copy = [...oldModels];
            copy.splice(index, 1);
            onChange((filterModels === undefined) ? copy : filterModels(copy));
            return copy;
        });
    }

    const setModel = (index: number) => (modelOrAction: any | ((prevState: any) => any)) : void => {
        setModels((models) => {
            let copy = [...models];
            const oldModel = copy[index];
            copy[index] = (typeof modelOrAction === 'function') ? modelOrAction(oldModel) : modelOrAction;
            onChange((filterModels === undefined) ? copy : filterModels(copy));
            return copy;
        })
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h6'>
                    {label}:
                </Typography>
                <Tooltip title='Добавить' className={classes.headerBtn}>
                    <IconButton onClick={addEmptyModel}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={'head_' + index}>
                                    {column.name}
                                </TableCell>
                            ))}
                            <TableCell key={'head_actions'}>
                                Действия
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {models.map((model, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={'row_' + model.ID}>
                                    {columns.map((column, columnIndex) => {
                                        return (
                                            <TableCell key={'col_' + columnIndex}>
                                                {renderField(column, model, setModel(index))}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'head_actions'}>
                                        <Tooltip title='Удалить'>
                                            <IconButton onClick={removeModel(index)}>
                                                <RemoveCircleOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
  );
}

export default ReverseFk;