import HttpClient from 'hex/http/client';
import {LoginResult} from './types';
import BrowserCache from 'hex/cache';

// Поле в локальном хранилище
const AUTH_LOCAL_STORAGE_NAME = "hex.auth.userdata";

// Класс для реализации авторизации и обновления состояния
export default class Auth
{
    // Путь к серверу
    private _serverPath: string;

    // Подписчики событий авторизации
    private _authStateSubscribers : Function[];

    // Результат авторизации
    private _authResult?: LoginResult;

    // Авторизован ли пользователь?
    private _isAuthentificated: boolean | null;

    // Таймер для получения нового токена
    private _timer? : NodeJS.Timeout;

    private _browserCache : BrowserCache;

    // Конструктор класса
    public constructor(serverPath: string)
    {
        this._serverPath = serverPath;
        this._authStateSubscribers = [];
        this._isAuthentificated = null;
        this._browserCache = new BrowserCache(AUTH_LOCAL_STORAGE_NAME);
    }

    // Инициализация хранилища и попытка осуществления входа сразу же
    public initialize()
    {
        // На случай, если вызовут повторно, или не в том порядке
        if (this._isAuthentificated !== null)
        {
            return;
        }

        // Проверяем наличие учетки в хранилище
        const savedData = this._browserCache.getValue() as LoginResult | undefined;

        // Если есть, и он не истек - запускаем процедуру обновления токена
        if ((savedData !== undefined) && (this.getRefreshTime(savedData.accessToken) > 0))
        {
            this._authResult = savedData;
            this.refreshToken();
        }
        else
        {
            this.setAuthState();
        }
    }

    // Вход в систему
    public login(username: string, password: string) : Promise<boolean | null>
    {
        let client = this.getHttpClient();

        this._isAuthentificated = null;
        this.authStateChanged();

        return new Promise((resolve, reject) => {
            client.post('/api/auth/login', {
                username, password
            })
            .then((response: LoginResult) => {
                this.setAuthState(response);
                resolve(this._isAuthentificated);
            }).catch((reason) => {
                this.setAuthState();
                reject(reason)
            });
        });
    }

    // Выход из системы:
    public logout()
    {
        this.setAuthState();
    }

    // Делаем список следящих, и если кто то удалился, то заполняем
    // место пустыми функциями (дабы не менять индексы и не путать всех)
    public subscribe(fn : Function) : Function
    {
        const index = this._authStateSubscribers.push(fn) - 1;
        
        return () => {
            try {
                this._authStateSubscribers[index] = () => {}
            } catch (err) {
                console.warn("Unable to unsubscribe. Maybe you already did?");
            }
        }
    }

    // Получаем Http клиент сразу с токеном, если он есть
    public getHttpClient()
    {
        return  new HttpClient(
                    this._serverPath, 
                    this._authResult ? this._authResult.accessToken : undefined, 
                    this._authResult ? () => this.setAuthState() : undefined
                );
    }

    // Проверка, авторизован ли пользователь
    public isAuthentificated()
    {
        return this._isAuthentificated;
    }

    // Смена статуса пользователя
    private authStateChanged() {
        this._authStateSubscribers.forEach((authSubscriber) => authSubscriber(this._isAuthentificated));
    }

    // Устанавливаем статус авторизации
    private setAuthState(state?: LoginResult) {
        let stateUpdated: boolean = false;

        if (state === undefined)
        {
            stateUpdated = this._isAuthentificated !== null ? this._isAuthentificated : true;
            this._isAuthentificated = false;
            this._authResult = undefined;

            if (this._timer !== undefined)
            {
                clearInterval(this._timer);
                this._timer = undefined;
            }

            this._browserCache.clearValue();
        }
        else
        {
            stateUpdated = this._isAuthentificated !== null ? !this._isAuthentificated : true;
            this._authResult = state;
            this._isAuthentificated = true;

            if (this._timer === undefined)
            {
                // Вызываем таймер обновления за минуту до истечения срока действия ключа
                this._timer = setInterval(this.refreshToken.bind(this), this.getRefreshTime() - (60 * 1000));
            }

            this._browserCache.setValue(this._authResult);
        }

        if (stateUpdated)
        {
            this.authStateChanged();
        }
    }

    // Процедура обновления токена
    private refreshToken() {
        if (this._authResult === undefined)
        {
            return;
        }

        let client = this.getHttpClient();

        client.post('/api/auth/refresh-token', {
            refreshToken: this._authResult.refreshToken
        })
        .then((response: LoginResult) => {
            this.setAuthState(response);
        })
        .catch(() => {
            this.setAuthState();
        })
    }

    // Получить время истечения токена
    private getRefreshTime(oldAccessToken?: string) : number
    {
        if ((this._authResult === undefined) && (oldAccessToken === undefined))
        {
            return 0;
        }

        const accessToken = oldAccessToken || (this._authResult as LoginResult).accessToken;

        if (!accessToken) {
            return 0;
        }

        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        return expires.getTime() - Date.now();
    }
}