import { FilterInfo, createQueryCallback } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column } from "components/data_modifiers/types";
import { Column as TableColumn } from 'components/data_table/types';
import DataContext from "odata/context";
import { Product, ProductPrices, SectionPrices } from "schema";

import MediasColumns, {defaultModel as MediaDefaultModel} from './productMedias';

import ProductPricesColumn, {defaultModel as ProductPricesDefaultModel} from './productPrices';


const columns : Array<TableColumn<Product>> = [
    {
        type: 'string',
        name: 'Наименование',
        field: 'Name',
        sort: true,
    },
    {
        type: 'image',
        name: 'Изображение',
        field: 'ImagePreview',
        sort: false,
    },
    {
        type: 'bool',
        name: 'Опубликовано',
        field: 'Published',
        sort: true,
    },
    {
        type: 'number',
        name: 'Сортировка',
        field: 'Sort',
        sort: true,
    },
    {
        type: 'datetime',
        name: 'Дата изменения',
        field: 'Updated',
        sort: true,
    },
    {
        type: 'datetime',
        name: 'Дата создания',
        field: 'Created',
        sort: true,
    },
    {
        type: 'number',
        name: 'Количество в упаковке',
        field: 'TotalInPackage',
        sort: true,
    },
    {
        type: 'string',
        name: 'Единица измерения',
        field: 'Unit',
        sort: true,
    }
];

export const filters: Array<FilterInfo<DataContext, Product>> = [
    {
        type: 'text',
        label: 'Наименование',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Name.includes(value), { value: value })),
    },
    {
        type: 'bool',
        label: 'Опубликовано',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Published === value, { value: value })),
    },
];

export const modifiers = (categoryId: string, sections: SectionPrices[] = []) : DataModifiersArgs<Product> =>
{
    return {
        modelName: 'Products',
        deleteData: {
            text: (model) => `Вы действительно хотите удалить товар "${model.Name}"?`,
        },
        newData: {
            pages: [
                {
                    label: 'Информация о товаре',
                    columns: [
                        {
                            type: 'string',
                            name: 'Наименование',
                            field: 'Name',
                            sort: true,
                        },
                        {
                            type: 'image',
                            name: 'Изображение',
                            field: 'ImagePreview',
                            sort: false,
                        },
                        {
                            type: 'bool',
                            name: 'Опубликовано',
                            field: 'Published',
                            sort: true,
                        },
                        {
                            type: 'number',
                            name: 'Сортировка',
                            field: 'Sort',
                            sort: true,
                        },
                        {
                            type: 'decimal',
                            name: 'Количество в упаковке',
                            field: 'TotalInPackage',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Единица измерения',
                            field: 'Unit',
                            sort: true,
                        },
                        {
                            type: 'slug',
                            name: 'Символьная ссылка',
                            field: 'Slug',
                            related: 'Name'
                        },
                        {
                            type: 'string',
                            name: 'Объём',
                            field: 'Space',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Вес',
                            field: 'Weight',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Папка с моделями 360',
                            field: 'ModelFolder',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Идентификатор 1С',
                            field: 'ExtModelID',
                            sort: true,
                        },
                    ],
                },
                {
                    label: 'Описание',
                    columns: [
                        {
                            type: 'bigtext',
                            name: 'Краткое описание',
                            field: 'ShortDescription',
                        },
                        {
                            type: 'htmltext',
                            name: 'Описание',
                            field: 'FullDescription',
                        },
                    ],
                },
                {
                    label: 'Медиа файлы',
                    columns: [
                        {
                            type: 'medias',
                            field: 'Medias',
                            name: 'Медиа данные',
                            columns: MediasColumns as Column<any>[],
                            defaultModel: MediaDefaultModel,
                        }
                    ],
                },
                {
                    label: 'Цены',
                    columns: [
                        {
                            type: 'reverse_fk',
                            name: 'Цены на товар',
                            field: 'Prices',
                            columns: ProductPricesColumn as Column<any>[],
                            defaultModel: ProductPricesDefaultModel,
                            beforeCreate: (relatedModel: ProductPrices, allModels: ProductPrices[]) => {
                                if (allModels.length === sections.length)
                                {
                                    return null;
                                }

                                var index = allModels.length;

                                relatedModel.SectionPrice = sections[index];
                                relatedModel.SectionPriceId = sections[index].ID;

                                return relatedModel;
                            },
                            initialModels: () => sections.map(section => ({...ProductPricesDefaultModel, SectionPrice: section, SectionPriceId: section.ID})),
                            filterModels: (models: Array<ProductPrices>) => models.filter(x => x.Value !== undefined),
                        }
                    ],
                }
            ],
            defaultModel: {
                ID: undefined as any,
                Published: true,
                Sort: 0,
                CategoryId: categoryId,
                TotalInPackage: 1,
                Unit: 'шт.',
                Medias: [],
                Prices: [],
            }
        },
        updateData: {
            pages: [
                {
                    label: 'Информация о товаре',
                    columns: [
                        {
                            type: 'string',
                            name: 'Наименование',
                            field: 'Name',
                            sort: true,
                        },
                        {
                            type: 'image',
                            name: 'Изображение',
                            field: 'ImagePreview',
                            sort: false,
                        },
                        {
                            type: 'bool',
                            name: 'Опубликовано',
                            field: 'Published',
                            sort: true,
                        },
                        {
                            type: 'number',
                            name: 'Сортировка',
                            field: 'Sort',
                            sort: true,
                        },
                        {
                            type: 'datetime',
                            name: 'Дата изменения',
                            field: 'Updated',
                            sort: true,
                        },
                        {
                            type: 'datetime',
                            name: 'Дата создания',
                            field: 'Created',
                            sort: true,
                        },
                        {
                            type: 'decimal',
                            name: 'Количество в упаковке',
                            field: 'TotalInPackage',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Единица измерения',
                            field: 'Unit',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Символьная ссылка',
                            field: 'Slug'
                        },
                        {
                            type: 'string',
                            name: 'Объём',
                            field: 'Space',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Вес',
                            field: 'Weight',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Папка с моделями 360',
                            field: 'ModelFolder',
                            sort: true,
                        },
                        {
                            type: 'string',
                            name: 'Идентификатор 1С',
                            field: 'ExtModelID',
                            sort: true,
                        },
                    ],
                },
                {
                    label: 'Описание',
                    columns: [
                        {
                            type: 'bigtext',
                            name: 'Краткое описание',
                            field: 'ShortDescription',
                        },
                        {
                            type: 'htmltext',
                            name: 'Описание',
                            field: 'FullDescription',
                        },
                    ],
                },
                {
                    label: 'Медиа файлы',
                    columns: [
                        {
                            type: 'medias',
                            field: 'Medias',
                            name: 'Медиа данные',
                            columns: MediasColumns as Column<any>[],
                            defaultModel: MediaDefaultModel,
                        }
                    ],
                },
                {
                    label: 'Цены',
                    columns: [
                        {
                            type: 'reverse_fk',
                            name: 'Цены на товар',
                            field: 'Prices',
                            columns: ProductPricesColumn as Column<any>[],
                            defaultModel: ProductPricesDefaultModel,
                            beforeCreate: (relatedModel: ProductPrices, allModels: ProductPrices[]) => {
                                if (allModels.length === sections.length)
                                {
                                    return null;
                                }

                                var data = sections.filter(x => allModels.findIndex(y => y.SectionPriceId === x.ID) === -1);

                                relatedModel.SectionPrice = data[0];
                                relatedModel.SectionPriceId = data[0].ID;

                                return relatedModel;
                            },
                            initialModels: () => sections.map(section => ({...ProductPricesDefaultModel, SectionPrice: section, SectionPriceId: section.ID})),
                            filterModels: (models: Array<ProductPrices>) => models.filter(x => x.Value !== 0),
                        }
                    ],
                }
            ],
        },
        publishField: 'Published',
    }
}

export default columns;