// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"

export interface Schema {
    Accounts?:              HiddenAccount[];
    Actuals?:               Actual[];
    ActualTags?:            ActualTag[];
    Blogs?:                 Blog[];
    BlogTags?:              BlogTag[];
    CartPositions?:         CartPosition[];
    Carts?:                 Cart[];
    Categories?:            Category[];
    Orders?:                Order[];
    ProductMedias?:         ProductMedia[];
    ProductPrices?:         ProductPrices[];
    Products?:              Product[];
    SectionPrices?:         SectionPrices[];
    Sections?:              Section[];
    Slides?:                Slider[];
    TextBlocks?:            TextBlock[];
    UserCompanies?:         UserCompany[];
    UserInfos?:             UserInfo[];
    UserShipmentAddresses?: UserShipmentAddress[];
}

export interface HiddenAccount {
    DateJoined?: string;
    ID:          string;
    IsActive:    boolean;
    IsSuperuser: boolean;
    LastLogin?:  string;
    Name?:       string;
    Password?:   string;
    Role:        EAccountRole;
    UserName?:   string;
    UserInfo?: UserInfo;
}

export enum EAccountRole {
    Administrator = "ADMINISTRATOR",
    Customer = "CUSTOMER",
    TestCustomer = "TEST_CUSTOMER",
}

export interface Actual {
    Created?:   string;
    ID:        string;
    Product?:  Product;
    ProductId: string;
    Sort:      number;
    Tag?:      ActualTag;
    TagId:     string;
    Updated?:   string;
}

export interface ActualTag {
    Actuals?:  Actual[];
    Created?:   string;
    ID:        string;
    Name?:     string;
    Published: boolean;
    Sort:      number;
    Updated?:   string;
}

export interface ProductPrices {
    Created?:        string;
    ID:             string;
    Product?:       Product;
    ProductId:      string;
    SectionPrice?:  SectionPrices;
    SectionPriceId: string;
    Updated?:        string;
    Value:          number;
}

export interface ProductMedia {
    ID:        string;
    Media?:    string;
    Product?:  Product;
    ProductId: string;
}

export interface Product {
    Category?:         Category;
    CategoryId:        string;
    Created?:           string;
    FullDescription?:  string;
    ID:                string;
    ImagePreview?:     string;
    Medias?:           ProductMedia[];
    Name?:             string;
    Prices?:           ProductPrices[];
    ProductPreview?:   string;
    Published?:         boolean;
    ShortDescription?: string;
    Slug?:             string;
    Sort?:              number;
    TotalInPackage?:    number;
    Unit?:             string;
    Updated?:           string;
    ModelFolder?:      string;
    Weight?:           string;
    Space?:            string;
    ExtModelID?:       string;
}

export interface SectionPrices {
    ID:         string;
    Name?:      string;
    Section?:   Section;
    SectionId:  string;
    Threshold?: number;
}

export interface Section {
    Created?:           string;
    FullDescription?:  string;
    ID:                string;
    Image?:            string;
    Name?:             string;
    Published?:         boolean;
    SectionPrices?:    SectionPrices[];
    ShortDescription?: string;
    Sort?:              number;
    Updated?:           string;
}

export interface Category {
    Created?:           string;
    FullDescription?:  string;
    ID:                string;
    Image?:            string;
    Name?:             string;
    Published?:         boolean;
    Section?:          Section;
    SectionId:         string;
    ShortDescription?: string;
    Slug?:             string;
    Sort:              number;
    Updated?:           string;
}

export interface Blog {
    Created?:           string;
    FullDescription?:  string;
    ID:                string;
    Image?:            string;
    Name?:             string;
    Preview?:          string;
    Published:         boolean;
    ShortDescription?: string;
    Slug?:             string;
    Sort:              number;
    Tag?:              BlogTag;
    TagId?:             string;
    Updated?:           string;
}

export interface BlogTag {
    Blogs?:    Blog[];
    Created?:   string;
    ID:        string;
    Name?:     string;
    Published: boolean;
    Sort:      number;
    Updated?:   string;
}

export interface CartPosition {
    Amount:    number;
    ID:        string;
    Price:     number;
    Product?:  Product;
    ProductId: string;
}

export interface Order {
    Account?:      Account;
    AccountId:     string;
    Address?:      string;
    Cart?:         Cart;
    CartId:        string;
    Created:       string;
    DeliveryPrice: number;
    ID:            string;
    InSystem:      boolean;
    WasPayed:      boolean;
    Number?:       string;
    Payer?:        string;
    PaymentType:   EPaymentType;
    ShipmentType?: string;
    TotalPrice:    number;
    Updated:       string;
}

export interface Cart {
    Account?:       Account;
    CartPositions?: CartPosition[];
    ID:             string;
    Order?:         Order;
}

export interface Account {
    DateJoined:    string;
    ID:            string;
    IsActive:      boolean;
    IsSuperuser:   boolean;
    LastLogin:     string;
    Name?:         string;
    PasswordHash?: string;
    Role:          EAccountRole;
    UserName?:     string;
}

export enum EPaymentType {
    Bill = "BILL",
    Card = "CARD",
    Cash = "CASH",
}

export interface Slider {
    Created?:   string;
    ID:        string;
    Image?:    string;
    Link?:     string;
    Name?:     string;
    Published: boolean;
    Sort:      number;
    Updated?:   string;
}

export interface TextBlock {
    ID:     string;
    Token?: string;
    Value?: string;
}

export interface UserShipmentAddress {
    Address?:   string;
    City?:      string;
    ID:         string;
    UserInfo?:  UserInfo;
    UserInfoId: string;
}

export interface UserInfo {
    Account?:               Account;
    AccountId:              string;
    Address?:               string;
    City?:                  string;
    Comment?:               string;
    ID:                     string;
    Phone?:                 string;
    ExtSystemId?:           string;
    SectionPrices?:         SectionPrices[];
    UserCompanies?:         UserCompany[];
    UserShipmentAddresses?: UserShipmentAddress[];
}

export interface UserCompany {
    ID:         string;
    Inn?:       string;
    Name?:      string;
    ExtSystemId?: string;
    UserInfo?:  UserInfo;
    UserInfoId: string;
}
