import React from 'react';
import { EPaymentType, Order } from 'schema';
import Typography from '@material-ui/core/Typography';
import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

// Props:
type OrderViewProps = {
    model: Order;
};

// Element:
const OrderView : React.FunctionComponent<OrderViewProps> = ({model}) => {
    const renderPaymentType = (paymentType: EPaymentType) => {
        switch (paymentType) {
            case EPaymentType.Bill:
                return "Счет";
            case EPaymentType.Card:
                return "Картой";
            case EPaymentType.Cash:
                return "Наличные";
            default:
                return "Неизвестно";
        }
    }

    return (
        <>
            <Typography variant='h6'>
                Заказ #{model.Number}
            </Typography>
            <Divider />
            <Typography variant='h6'>
                Способ доставки: {model.ShipmentType}
            </Typography>
            {model.ShipmentType !== "Самовывоз со склада" &&
                <Typography variant='h6'>
                    Адрес доставки: {model.Address}
                </Typography>
            }
            
            <Typography variant='h6'>
                Способ оплаты: {renderPaymentType(model.PaymentType)}
            </Typography>
            {
                model.PaymentType === EPaymentType.Bill && 
                <Typography variant='h6'>
                    Компания, ИНН: {model.Payer}
                </Typography>
            }
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Наименование
                        </TableCell>
                        <TableCell>
                            Количество
                        </TableCell>
                        <TableCell>
                            Цена
                        </TableCell>
                        <TableCell>
                            Стоимость
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {model.Cart?.CartPositions?.map((position) => {
                        return (
                            <TableRow key={position.ID}>
                                <TableCell>
                                    {position.Product?.Name}
                                </TableCell>
                                <TableCell>
                                    {position.Amount}
                                </TableCell>
                                <TableCell>
                                    {position.Price / position.Amount}
                                </TableCell>
                                <TableCell>
                                    {position.Price}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
}

export default OrderView;