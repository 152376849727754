import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import { QueryCallback } from 'components/data_filters/types';
import { useModels } from 'hex/hooks/data';
import { useOdataContext } from 'odata/context';
import React from 'react';
import { OdataContext } from 'ts2odata';

// Props:
type ForeignKeyFieldProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    getName: (model: any) => string;
    query: QueryCallback<OdataContext<any>, any>;
};

// Element:
const ForeignKeyField : React.FunctionComponent<ForeignKeyFieldProps> = ({label, value, onChange, getName, query}) => {
    // Data Context:
    var context = useOdataContext();

    var [count, models] = useModels(context, query);

    const renderMenuEntries = React.useMemo(() => {
        if (models === null)
        {
            return null;
        }
        
        return models.map((record, index) => {
            return (
                <MenuItem key={"menu_" + index.toString()} value={record.ID}>{getName(record)}</MenuItem>
            );
        });
    }, [models]);

    if (models === null)
    {
        return (<div>Loading...</div>);
    }

    const onChangeEventWrapper = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) => {
        onChange(event.target.value);
    };

    return (
        <FormControl style={{width: "100%",}}>
            <InputLabel>{label}</InputLabel>
            <Select input={<Input /> } onChange={onChangeEventWrapper} value={value} fullWidth>
                {renderMenuEntries}
            </Select>
        </FormControl>
    );
}

export default ForeignKeyField;