import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    content: {

    },
    buttons: {
      marginTop: '25px',
    },
    filter: {
      marginBottom: '10px',
    }
  }),
);

export default useStyles;