import { Typography } from '@material-ui/core';
import Modal from 'components/modal';
import ApiDelete from 'hex/api/delete';
import { useAuth } from 'hex/hooks/auth';
import React from 'react';
import { DeleteDataOut, ModelType } from '../types';

// Props:
type DeleteModalProps<TModel extends ModelType> = DeleteDataOut<TModel> & {
    modelName: string;
    refresh: () => void;
}

// Element:
function DeleteModal<TModel extends ModelType>({modelName, open, model, text, closeAction, refresh} : DeleteModalProps<TModel>) {
    // Auth:
    const auth = useAuth();
    
    // Consts:
    const modalText = () => {
        if (model)
        {
            if (text)
            {
                return typeof(text) == 'function' ? text(model as TModel) : text;
            }
            else
            {
                return 'Вы действительно хотите удалить этот объект?';
            }
        }
    }

    // Events:
    const deleteAction = () => {
        if (model)
        {
            const client = auth.getHttpClient();
            ApiDelete(client, modelName, model)
            .then(() => {
                refresh();
                closeAction();
            });
        }
    };

    if (open === false)
    {
        return null;
    }

    return (
        <Modal  open={open} title='Удаление объекта' contentTitle='' 
                closeButtonText='Отмена' closeButtonAction={closeAction}
                actionButtonText='Удалить' actionButtonAction={deleteAction}>
            <Typography variant='body1'>
                {modalText()}
            </Typography>
        </Modal>
    );
}

export default DeleteModal;