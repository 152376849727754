export function processHttpError(reason : Response) {
    //alert(JSON.stringify(reason));
    //alert(reason.json());
}

export function getTextError(reason : Response) {
    switch (reason.status) {
        case 400:
            return "Ошибка сервера. Приносим извинения за доставленные неудобства. Технический специалист уже уведомлён.";
        case 401:
            return "Ошибка авторизации. Неправильные логин/пароль.";
        default:
            return "Неизвестная ошибка.";
    }
}
