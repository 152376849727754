import React from 'react';
import { useDataFilters } from 'components/data_filters/hook';
import { useModels, usePagedModels } from 'hex/hooks/data';
import { useDataModifiers } from 'components/data_modifiers/hooks';
import { useOrder } from 'hex/hooks/models';
import columns, { filters, modifiers } from 'model_data/products';
import DataContext, { useOdataContext } from 'odata/context';
import { useHistory, useParams } from 'react-router-dom';
import DataFilters from 'components/data_filters';
import DataTable from 'components/data_table';
import DataModifiers from 'components/data_modifiers';
import { IconButton } from '@material-ui/core';
import { ArrowBackIosOutlined } from '@material-ui/icons';

// Props:
type ProductsPageProps = {

};

// Element:
const ProductsPage : React.FunctionComponent<ProductsPageProps> = () => {
    // RouteData:
    const { section, category } = useParams<{section: string, category: string}>();
    
    // Data Context:
    var context = useOdataContext();

    // Query:
    const queryCallback = React.useCallback((context: DataContext) => context.Products.expand(i => i.Medias).expand(i => i.Prices).thenExpand(i => i.SectionPrice).filter(i => i.CategoryId === category, {category: `guid'${category}'`}), [category]);
    const queryPricesCallback = React.useCallback((context: DataContext) => context.SectionPrices.filter(i => i.SectionId === section, {section: `guid'${section}'`}), [section]);
    
    // Order:
    const orderData = useOrder(columns[0].field);

    // Filters:
    const filtersData = useDataFilters(filters, queryCallback);

    // Models:
    const [, products, pagination, refresh] = usePagedModels(context, filtersData.resultQuery, orderData);
    const [, prices] = useModels(context, queryPricesCallback);

    // Modifiers:
    const modifiersData = useDataModifiers({...modifiers(category, prices !== null ? prices : []), refresh});
 
    // History:
    const history = useHistory();

    // Loading:
    if (products === null)
    {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
    
    return (
        <>
            <div style={{display: 'flex', }}>
                <IconButton onClick={() => history.push(`/catalog/${section}/`)}>
                    <ArrowBackIosOutlined/>
                </IconButton>
                <h1>Товары в категории</h1>
            </div>
 
             <DataFilters filtersData={filtersData} />
             <DataTable models={products} columns={columns} orderData={orderData} pagination={pagination}
                        actions={modifiersData.actions} globalActions={modifiersData.globalActions} selectedActions={modifiersData.selectedActions} />
             <DataModifiers modifiersData={modifiersData} />
         </>
     );
}

export default ProductsPage;