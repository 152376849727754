import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Props:
type OrdersSidebarProps = {

};

// Element:
const OrdersSidebar : React.FunctionComponent<OrdersSidebarProps> = () => {
    const history = useHistory();

    return (
        <List>
            <ListItem button key={"users"} onClick={() => history.push('/users/')}>
                <ListItemText color='primary' primary='Пользователи' />
            </ListItem>
            <ListItem button key={"employes"} onClick={() => history.push('/users/employes/')}>
                <ListItemText color='primary' primary='Сотрудники' />
            </ListItem>
            <ListItem button key={"orders"} disabled>
                <ListItemText color='primary' primary='Заказы' />
            </ListItem>
        </List>
    );
}

export default OrdersSidebar;