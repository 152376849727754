import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

// Props:
type LookupFieldProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    lookup: Array<{label: string, value: any}>;
};

// Element:
const LookupField : React.FunctionComponent<LookupFieldProps> = ({label, value, onChange, lookup}) => {
    const onChangeEventWrapper = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) => {
        onChange(event.target.value);
    };

    const renderMenuEntries = React.useMemo(() => {
        return lookup.map((record, index) => {
            return (
                <MenuItem key={"menu_" + index.toString()} value={record.value}>{record.label}</MenuItem>
            );
        });
    }, [lookup]);

    return (
        <FormControl style={{width: "100%",}}>
            <InputLabel>{label}</InputLabel>
            <Select input={<Input /> } onChange={onChangeEventWrapper} value={value} fullWidth>
                {renderMenuEntries}
            </Select>
        </FormControl>
    );
}

export default LookupField;