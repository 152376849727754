import Modal from 'components/modal';
import React from 'react';
import { DataViewerInfo, ModelType } from './types';

// Props:
type DataViewerProps<TModel extends ModelType> = {
    data: DataViewerInfo<TModel>;
};

// Element:
const DataViewer = <TModel extends ModelType>(props: DataViewerProps<TModel>) => {
    const {data} = props;

    if ((data.open === false) || (data.model === undefined))
    {
        return null;
    }

    return (
        <Modal open={data.open} title='Просмотр объекта' contentTitle='' closeButtonText='Закрыть' closeButtonAction={data.closeAction}>
            {data.componentToRender(data.model)}
        </Modal>
    );
}

export default DataViewer;