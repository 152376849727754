import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { useHistory} from 'react-router-dom';

// Props:
type EmployesSidebarProps = {

};

// Element:
const EmployesSidebar : React.FunctionComponent<EmployesSidebarProps> = () => {
    const history = useHistory();

    return (
        <List>
            <ListItem button key={"users"} onClick={() => history.push('/users/')}>
                <ListItemText color='primary' primary='Пользователи' />
            </ListItem>
            <ListItem button key={"employes"} disabled>
                <ListItemText color='primary' primary='Сотрудники' />
            </ListItem>
            <ListItem button key={"orders"} onClick={() => history.push('/users/orders/')}>
                <ListItemText color='primary' primary='Заказы' />
            </ListItem>
        </List>
    );
}

export default EmployesSidebar;