import React from 'react';
import { Redirect } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Copyright from './copyright'
import {useAuth, useAuthState} from 'hex/hooks/auth';

import useStyles from './style'
import * as Constants from 'text_constants/titles'
import { getTextError } from 'hex/utils/errors';

// @ts-ignore
import { NotificationManager } from 'react-notifications';

// Props:
type FormProps = {};

const Form : React.FunctionComponent<FormProps> = () => {
    const classes = useStyles();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    // Auth:
    const auth = useAuth();
    const signedIn = useAuthState();
    var isLoading = signedIn === null;

    const loginAction = () => {
        if (email !== '' && password !== '') {
            auth.login(email, password)
            .catch((reason) => {
                var text = getTextError(reason);
                NotificationManager.error(text, "Ошибка");
            })
        }
    }

    if ((auth !== undefined) && (auth.isAuthentificated()))
    {
        return (<Redirect to="/" />);
    }

    return  <>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {Constants.titlesSigninText}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField variant="outlined" margin="normal" required fullWidth id="email" label={'Имя пользователя'} name="email" autoComplete="email" autoFocus
                                    onChange={(event) => setEmail(event.target.value)} disabled={isLoading} />
                        <TextField variant="outlined" margin="normal" required fullWidth name="password" label={'Пароль'} type="password" id="password" autoComplete="current-password"
                                    onChange={(event) => setPassword(event.target.value)} disabled={isLoading} />
                        
                        <Button fullWidth variant="contained" color="primary" className={classes.submit} disabled={isLoading} onClick={loginAction}>
                            Войти
                        </Button>
                        <Box mt={5}>
                            <Copyright name={Constants.titlesCopyrightNameText} link={Constants.titlesCopyrightLink} />
                        </Box>
                    </form>
                </div>
            </>
}

export default Form;