import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import {ActionType, Column, ModelType, Order} from './types';
import {useStyles} from './styles';
import EnhancedTableToolbar from './toolbar';
import EnhancedTableHead from './tablehead';
import { renderField } from './dataTypes';
import ItemMenu from './itemMenu';
import { GlobalActionType, SelectedActionType } from 'components/data_modifiers/types';
import { IconButton, Tooltip } from '@material-ui/core';


// Props:
type DataTableProps<TModel extends ModelType> = {
    models: Array<TModel>;
    columns: Array<Column<TModel>>;
    orderData: {
        orderBy: keyof TModel;
        direction: Order;
        setOrder: (orderBy: keyof TModel, direction: Order) => void;
    };
    pagination: {
        options: Array<number>;
        count: number;
        rowsPerPage: number;
        page: number;
        handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
        handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    };
    actions?: Array<ActionType<TModel>>;
    globalActions?: Array<GlobalActionType>;
    selectedActions?: Array<SelectedActionType<TModel>>;
};

// Element:
function DataTable<TModel extends ModelType>({models, columns, orderData, pagination, actions, globalActions, selectedActions} : DataTableProps<TModel>) {
    // Styles:
    const classes = useStyles();

    // Selected:
    const [selected, setSelected] = React.useState<Array<string>>([]);

    // HeadCells:
    const headCells = React.useMemo(() => columns.map((value) => {
        return {id: value.field, label: value.name, sort: value.sort};
    }), [columns]);

    // Events:
    const handleSelectAllClick = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(models.map((value) => value.ID));
            return;
        }
        
        setSelected([]);
    }, [models, setSelected]);

    const handleRequestSort = React.useCallback((event: React.MouseEvent<unknown>, property: keyof TModel) => {
        const isAsc = orderData.orderBy === property && orderData.direction === 'asc';
        orderData.setOrder(property, isAsc ? 'desc' : 'asc');
    }, [orderData]);

    const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, model: TModel) => {
        const selectedIndex = selected.indexOf(model.ID);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, model.ID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    // Helpers:
    const isSelected = React.useCallback((model: TModel) => selected.indexOf(model.ID) !== -1, [selected]);

    const renderSelectedBtns = () => {
        if (selectedActions === undefined)
        {
            return <></>
        }

        return selectedActions.map((action, index) => {
            return (
                <Tooltip title={action.label} key={'btn_' + index}>
                    <IconButton aria-label={action.label} onClick={() => {
                        const selectedModels = models.filter(model => selected.indexOf(model.ID) !== -1);
                        action.action(selectedModels);
                    }}>
                        {action.icon}
                    </IconButton>
                </Tooltip>
            );
        })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} globalActions={globalActions} selectedButtons={renderSelectedBtns()} />
                <TableContainer>
                    <Table className={classes.table} size={'medium'}>
                        <EnhancedTableHead  classes={classes} numSelected={selected.length} order={orderData.direction} 
                                            orderBy={orderData.orderBy} onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort} rowCount={models.length} headCells={headCells} actions={actions !== undefined} />
                        <TableBody>
                            {models.map((model, index) => {
                                const isItemSelected = isSelected(model);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow hover onClick={(event) => handleClick(event, model)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={'row_' + model.ID} selected={isItemSelected}>
                                        <TableCell key='checkbox' padding="checkbox">
                                            <Checkbox color='primary' checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                        </TableCell>
                                        {
                                            actions &&
                                            <TableCell key='actions'>
                                                <ItemMenu actions={actions} model={model} />
                                            </TableCell>
                                        }
                                        {columns.map((cell, cellIndex) => {
                                            return (<TableCell key={'cell_' + cellIndex}>
                                                        {renderField(cell, model[cell.field])}
                                                    </TableCell>)
                                        })}
                                    </TableRow>
                                );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination    rowsPerPageOptions={pagination.options} component="div" count={pagination.count} 
                                    rowsPerPage={pagination.rowsPerPage} page={pagination.page} onChangePage={pagination.handleChangePage} 
                                    onChangeRowsPerPage={pagination.handleChangeRowsPerPage} />
            </Paper>
        </div>
    );
}

export default DataTable;