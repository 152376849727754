import React from 'react';
import TextField from '@material-ui/core/TextField';

// Props:
type TextFilterProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
};

// Element:
const TextFilter : React.FunctionComponent<TextFilterProps> = ({label, value, onChange}) => {
    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    return (
        <TextField margin="dense" label={label} type="text" fullWidth onChange={onChangeEventWrapper} value={value || ""} />
    );
}

export default TextFilter;