import { FilterInfo } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column as DataColumns } from "components/data_modifiers/types";
import DataContext from "odata/context";
import { SectionPrices } from "schema";


const columns : Array<DataColumns<SectionPrices>> = [
    {
        type: 'string',
        name: 'Наименование',
        field: 'Name',
        sort: false,
    },
    {
        type: 'number',
        name: 'Пороговое значение',
        field: 'Threshold',
        sort: false,
    },
];

export const filters: Array<FilterInfo<DataContext, SectionPrices>> = [];

export const defaultModel: SectionPrices = {
    ID: undefined as any,
    SectionId: undefined as any,
};

export const modifiers : DataModifiersArgs<SectionPrices> = {
    modelName: 'SectionPrices',
    deleteData: {
        text: (model) => `Вы действительно хотите удалить цену "${model.Name}"?`,
    },
    newData: {
        pages: [
            {
                label: 'Информация о ценах',
                columns: [
                    {
                        type: 'string',
                        name: 'Наименование',
                        field: 'Name',
                        sort: false,
                    },
                    {
                        type: 'number',
                        name: 'Пороговое значение',
                        field: 'Threshold',
                        sort: false,
                    },
                ],
            },
        ],
        defaultModel: {
            ID: undefined as any,
            SectionId: undefined as any,
        }
    },
}

export default columns;