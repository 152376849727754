import { List, ListItem, ListItemText } from '@material-ui/core';
import { useModels } from 'hex/hooks/data';
import DataContext, { useOdataContext } from 'odata/context';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Props:
type ProductsSidebarProps = {

};

// Element:
const ProductsSidebar : React.FunctionComponent<ProductsSidebarProps> = () => {
    // RouteData:
    const { section, category } = useParams<{section: string, category: string}>();
    
    // Data Context:
    var context = useOdataContext();

    // Query:
    const queryCallback = React.useCallback((context: DataContext) => context.Categories.expand(i => i.Section).filter(i => i.SectionId === section, {section: `guid'${section}'`}), [section]);

    // Models:
    const [, categories] = useModels(context, queryCallback);

    // History:
    const history = useHistory();

    if (categories === null)
    {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
    
    return (
        <List>
            {categories.length !== 0 && 
                <ListItem button disabled key={"section"} >
                    <ListItemText color='primary' primary={categories[0].Section ? categories[0].Section.Name : ""} />
                </ListItem>
            }
            {categories.map((cat) => (
                <ListItem button key={"category_" + cat.ID} onClick={() => history.push(`/catalog/${cat.SectionId}/${cat.ID}/`)} disabled={cat.ID === category}>
                    <ListItemText primary={cat.Name} />
                </ListItem>
            ))}
        </List>
    );
}

export default ProductsSidebar;