import { FilterInfo, createQueryCallback } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column } from "components/data_table/types";
import { Column as ModifiersColumn } from "components/data_modifiers/types";
import DataContext from "odata/context";
import { Section } from "schema";

import SectionPricesColumn, {defaultModel} from './sectionPrices';


const columns : Array<Column<Section>> = [
    {
        type: 'string',
        name: 'Наименование',
        field: 'Name',
        sort: true,
    },
    {
        type: 'image',
        name: 'Изображение',
        field: 'Image',
        sort: false,
    },
    {
        type: 'bool',
        name: 'Опубликовано',
        field: 'Published',
        sort: true,
    },
    {
        type: 'number',
        name: 'Сортировка',
        field: 'Sort',
        sort: true,
    },
    {
        type: 'datetime',
        name: 'Дата изменения',
        field: 'Updated',
        sort: true,
    },
    {
        type: 'datetime',
        name: 'Дата создания',
        field: 'Created',
        sort: true,
    }
];

export const filters: Array<FilterInfo<DataContext, Section>> = [
    {
        type: 'text',
        label: 'Наименование',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Name.includes(value), { value: value })),
    },
    {
        type: 'bool',
        label: 'Опубликовано',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Published === value, { value: value })),
    },
];

export const modifiers : DataModifiersArgs<Section> = {
    modelName: 'Sections',
    deleteData: {
        text: (model) => `Вы действительно хотите удалить раздел "${model.Name}"?`,
    },
    newData: {
        pages: [
            {
                label: 'Информация о разделе',
                columns: [
                    {
                        type: 'string',
                        name: 'Наименование',
                        field: 'Name',
                        sort: true,
                    },
                    {
                        type: 'image',
                        name: 'Изображение',
                        field: 'Image',
                        sort: false,
                    },
                    {
                        type: 'bool',
                        name: 'Опубликовано',
                        field: 'Published',
                        sort: true,
                    },
                    {
                        type: 'number',
                        name: 'Сортировка',
                        field: 'Sort',
                        sort: true,
                    },
                ],
            },
            {
                label: 'Описание',
                columns: [
                    {
                        type: 'bigtext',
                        name: 'Краткое описание',
                        field: 'ShortDescription',
                    },
                    {
                        type: 'htmltext',
                        name: 'Описание',
                        field: 'FullDescription',
                    },
                ],
            },
            {
                label: 'Цены раздела',
                columns: [
                    {
                        type: 'reverse_fk',
                        name: 'Цены раздела',
                        field: 'SectionPrices',
                        columns: SectionPricesColumn as ModifiersColumn<any>[],
                        defaultModel: defaultModel
                    },
                ],
            },
        ],
        defaultModel: {
            ID: undefined as any,
            Published: true,
            Sort: 0,
        }
    },
    updateData: {
        pages: [
            {
                label: 'Информация о разделе',
                columns: [
                    {
                        type: 'string',
                        name: 'Наименование',
                        field: 'Name',
                        sort: true,
                    },
                    {
                        type: 'image',
                        name: 'Изображение',
                        field: 'Image',
                        sort: false,
                    },
                    {
                        type: 'bool',
                        name: 'Опубликовано',
                        field: 'Published',
                        sort: true,
                    },
                    {
                        type: 'number',
                        name: 'Сортировка',
                        field: 'Sort',
                        sort: true,
                    },
                ],
            },
            {
                label: 'Описание',
                columns: [
                    {
                        type: 'bigtext',
                        name: 'Краткое описание',
                        field: 'ShortDescription',
                    },
                    {
                        type: 'htmltext',
                        name: 'Описание',
                        field: 'FullDescription',
                    },
                ],
            },
            {
                label: 'Цены раздела',
                columns: [
                    {
                        type: 'reverse_fk',
                        name: 'Цены раздела',
                        field: 'SectionPrices',
                        columns: SectionPricesColumn as ModifiersColumn<any>[],
                        defaultModel: defaultModel
                    },
                ],
            },
        ],
    },
    publishField: 'Published',
}

export default columns;