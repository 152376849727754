import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.default,
    },
}));


export default useStyles;
