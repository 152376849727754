import React from 'react';

import {useAuth, useAuthState} from 'hex/hooks/auth';
import { useStyles } from './styles';
import { AppBar, Button, ButtonGroup, CssBaseline, Drawer, Toolbar, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

// Props:
type LayoutProps = {
    sidebar?: React.ReactElement
};

// Element:
const Layout : React.FunctionComponent<LayoutProps> = ({children, sidebar}) => {
    // Auth State:
    const signedIn = useAuthState();

    // Auth:
    const auth = useAuth();
    
    // Classes:
    const classes = useStyles();

    // History:
    const history = useHistory();

    // Location:
    const location = useLocation();

    // States:
    const [btnEnabled, setBtnEnabled] = React.useState(true);

    // Refs:
    const inputFile = React.useRef<HTMLInputElement>(null);

    // Events:
    const callSync = () => {
        if (signedIn === true)
        {
            setBtnEnabled(false);

            var client = auth.getHttpClient();

            client.post("/api/sync")
            .then(() => {
                setTimeout(() => {
                    setBtnEnabled(true);
                }, 60 * 1000);
            })
            .catch(() => {
                setBtnEnabled(true);
            });
        }
    }

    const callPrices = () => {
        if (signedIn === true)
        {
            setBtnEnabled(false);

            var client = auth.getHttpClient();

            client.get_clean("/api/prices/export")
            .then( res => res.blob())
            .then( blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
                setBtnEnabled(true);
            })
            .catch(() => {
                setBtnEnabled(true);
            });
        }
    }

    const importPrices = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if ((signedIn === true) && (event.target.files !== null))
        {
            var formData = new FormData();
            var file = event.target.files[0];
            console.log(file);
            formData.append("file", file);

            setBtnEnabled(false);
            var client = auth.getHttpClient();

            client.postFile("/api/prices/import", formData)
            .then(() => {
                setBtnEnabled(true);
            })
            .catch(() => {
                setBtnEnabled(true);
            });
        }
    }

    const navigation = () => {
        return (
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.logoWrapper}>
                        <img src="/logo.png" alt="Logo" className={classes.logo}/>
                    </div>

                    <div className={classes.drawerData}>
                        {sidebar}
                    </div>
                </Drawer>
            </nav>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            {signedIn === true && navigation()}
            <main className={classes.content}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        <Typography variant="h6" noWrap className={classes.titleBar}>
                            Административная панель
                        </Typography>
                        <div className={classes.buttonsBar}>
                            {signedIn === true && <ButtonGroup color="secondary" aria-label="outlined primary button group">
                                <Button variant={'outlined'} disabled={!btnEnabled} onClick={()=>{inputFile.current !== null && inputFile.current.click()}}>Импорт цен</Button>
                                <Button variant={'outlined'} disabled={!btnEnabled} onClick={callPrices}>Получить цены</Button>
                                <Button variant={'outlined'} disabled={!btnEnabled} onClick={callSync}>Запрос изменений в 1С</Button>
                                <Button variant={location.pathname === '/' || location.pathname.startsWith('/catalog/') ? 'contained' : 'outlined'} onClick={() => history.push('/')}>Каталог</Button>
                                <Button variant={location.pathname.startsWith('/users/') ? 'contained' : 'outlined'} onClick={() => history.push('/users/')}>Пользователи</Button>
                                <Button variant={location.pathname.startsWith('/site/') ? 'contained' : 'outlined'} onClick={() => history.push('/site/slides/')}>Данные сайта</Button>
                                <Button onClick={() => auth.logout()}>Выход</Button>
                                <input id="myInput" type="file" ref={inputFile} style={{display: 'none'}} onChange={importPrices} />
                            </ButtonGroup>}
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentData}>
                    {children}
                </div>
            </main>
        </div>
    );
}

export default Layout;