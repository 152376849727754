import React from 'react';
import { DataModifiersOut } from './hooks';
import DeleteModal from './modals/delete';
import NewModal from './modals/new';
import UpdateModal from './modals/update';
import { ModelType } from './types';

// Props:
type DataModifiersProps<TModel extends ModelType> = {
    modifiersData: DataModifiersOut<TModel>
};

// Element:
function DataModifiers<TModel extends ModelType>({modifiersData, } : DataModifiersProps<TModel>) {
    
    return (
        <>
            {modifiersData.deleteData && modifiersData.deleteData.model && <DeleteModal {...modifiersData.deleteData} modelName={modifiersData.modelName} refresh={modifiersData.refresh} />}
            {modifiersData.newData && <NewModal {...modifiersData.newData} modelName={modifiersData.modelName} refresh={modifiersData.refresh} />}
            {modifiersData.updateData && modifiersData.updateData.model !== undefined && <UpdateModal {...modifiersData.updateData} modelName={modifiersData.modelName} refresh={modifiersData.refresh} model={modifiersData.updateData.model} />}
        </>
    );
}

export default DataModifiers;