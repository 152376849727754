export const titlesMainText = "Hippocrates CRM";
export const titlesCopyrightText = "Copyright © ";
export const titlesCopyrightStartYearText = "2021";
export const titlesCopyrightNameText = "Алформ";
export const titlesCopyrightLink = "http://alform.ru/";
export const titlesDeveloperText = "Developer:";
export const titlesDeveloperNameText = "Hex Soft";
export const titlesDeveloperLink = "http://alex-raven.com/";
export const titlesSigninText = "Вход в систему";

export const titlesUsersTitle = "Пользователи";