import React from 'react';
import { Avatar, Button } from '@material-ui/core';
import { BASE_SERVER_PATH, STATIC_PATH } from 'config/server';
import ApiFileUpload from 'hex/api/file';
import { useAuth } from 'hex/hooks/auth';

// Props:
type ImageFieldProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
};

// Element:
const ImageField : React.FunctionComponent<ImageFieldProps> = ({label, value, onChange}) => {
    // Auth:
    const auth = useAuth();

    // Events:
    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target.files !== null) && (event.target.files.length !== 0))
        {
            const client = auth.getHttpClient();
            ApiFileUpload(client, 'images', event.target.files[0] as any)
            .then((file) => {
                onChange(`images/${file}`);
            });
        }
    }

    return (
        <Button variant="contained" component="label">
            Загрузка {label}
            <input type="file" hidden onChange={handleCapture} />
            <Avatar alt="Изображение" src={value ? `${BASE_SERVER_PATH}${STATIC_PATH}${value}` : undefined} />
        </Button>
    );
}

export default ImageField;