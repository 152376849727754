import React from "react";
import BoolFilter from "./filters/bool";
import TextFilter from "./filters/text";
import { RenderTypes } from "./types";

export const renderFilter = (type: RenderTypes, label: string, setValue: (value?: any) => void, value?: any) => {
    switch (type) {
        case 'bool':
            return <BoolFilter label={label} value={value} onChange={setValue} />
        default:
            return <TextFilter label={label} value={value} onChange={setValue} />
    }
}