import CatalogSidebar from '../sidebars/catalog';
import CatalogPage from '../pages/catalog';

import CategoriesSidebar from '../sidebars/catalog/categories';
import CategoriesPage from '../pages/catalog/categories';

import ProductsSidebar from '../sidebars/catalog/categories/product';
import ProductsPage from '../pages/catalog/categories/product';

import ClientsSidebar from '../sidebars/users/clients';
import ClientsPage from '../pages/users/clients';

import EmployesSidebar from '../sidebars/users/employes';
import EmployesPage from '../pages/users/employes';

import OrdersSidebar from '../sidebars/users/orders';
import OrdersPage from '../pages/users/orders';

import SlidesSidebar from '../sidebars/site/slider';
import SlidesPage from '../pages/site/slider';

import BlogTagsSidebar from '../sidebars/site/blogs/blog_tags';
import BlogTagsPage from '../pages/site/blogs/blog_tags';

import BlogsSidebar from '../sidebars/site/blogs/blogs';
import BlogsPage from '../pages/site/blogs/blogs';

import ActualTagsSidebar from '../sidebars/site/actual/actual_tags';
import ActualTagsPage from '../pages/site/actual/actual_tags';

import ActualSidebar from '../sidebars/site/actual/actual';
import ActualPage from '../pages/site/actual/actual';

type RouteData = {
    component: React.ReactType,
    path: string,
};

export const getPagesRoutes = () => {
    var result : RouteData[] = [
        // Каталог
        {
            component: CatalogPage,
            path: '/',
        },
        // Категории
        {
            component: CategoriesPage,
            path: '/catalog/:section/',
        },
        // Товар
        {
            component: ProductsPage,
            path: '/catalog/:section/:category/',
        },
        // Страница клиентов
        {
            component: ClientsPage,
            path: '/users/',
        },
        // Страница сотрудников
        {
            component: EmployesPage,
            path: '/users/employes/',
        },
        // Страница заказов
        {
            component: OrdersPage,
            path: '/users/orders/',
        },
        // Страница Слайдов
        {
            component: SlidesPage,
            path: '/site/slides/',
        },
        // Страница Тэгов блога
        {
            component: BlogTagsPage,
            path: '/site/blog-tags/',
        },
        // Страница блога
        {
            component: BlogsPage,
            path: '/site/blogs/',
        },
        // Страница тэгов блока актуальных товаров
        {
            component: ActualTagsPage,
            path: '/site/actual-tags/',
        },
        // Страница тэгов блока актуальных товаров
        {
            component: ActualPage,
            path: '/site/actuals/',
        },
    ];

    return result;
}

export const getSideBarRoutes = () => {
    var result : RouteData[] = [
        // Каталог
        {
            component: CatalogSidebar,
            path: '/',
        },
        // Категории
        {
            component: CategoriesSidebar,
            path: '/catalog/:section/',
        },
        // Товар
        {
            component: ProductsSidebar,
            path: '/catalog/:section/:category/',
        },
        // Страница клиентов
        {
            component: ClientsSidebar,
            path: '/users/',
        },
        // Страница сотрудников
        {
            component: EmployesSidebar,
            path: '/users/employes/',
        },
        // Страница заказов
        {
            component: OrdersSidebar,
            path: '/users/orders/',
        },
        // Страница Слайдов
        {
            component: SlidesSidebar,
            path: '/site/slides/',
        },
        // Страница Тэгов блога
        {
            component: BlogTagsSidebar,
            path: '/site/blog-tags/',
        },
        // Страница блога
        {
            component: BlogsSidebar,
            path: '/site/blogs/',
        },
        // Страница тэгов блока актуальных товаров
        {
            component: ActualTagsSidebar,
            path: '/site/actual-tags/',
        },
        // Страница тэгов блока актуальных товаров
        {
            component: ActualSidebar,
            path: '/site/actuals/',
        },
    ];

    return result;
}