import React from 'react';

type Order = 'asc' | 'desc';

export function useOrder<TModel extends object>(defaultField: keyof TModel) {
    // Поле для сортировки
    const [orderBy, setOrderBy] = React.useState<keyof TModel>(defaultField);
    // Направление сортировки
    const [direction, setDirection] = React.useState<Order>('asc');

    // Назначить новый порядок (!!!)
    const setOrder = React.useCallback((order: keyof TModel, direction: Order) => {
        setOrderBy(order);
        setDirection(direction);
    }, [setOrderBy, setDirection]);

    return {
        orderBy: orderBy,
        direction: direction,
        setOrder: setOrder,
    };
}

const defaultPageOptions = [10, 20, 50, 100];

export const usePagination = (count: number, options: Array<number> = defaultPageOptions) => {
    // Строк на странице
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(100);
    
    // Страница
    const [page, setPage] = React.useState<number>(0);

    // Изменение страницы
    const handleChangePage = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    }, [setPage]);

    // Изменение количества строк на странице
    const handleChangeRowsPerPage = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, [setRowsPerPage, setPage]);

    return {
        options: options,
        count: count,
        rowsPerPage: rowsPerPage,
        page: page,
        handleChangePage,
        handleChangeRowsPerPage,
    };
}