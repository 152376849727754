import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 300;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      width: '100%',
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerData: {
      padding: theme.spacing(3),
    },
    content: {
      flexGrow: 1,
    },
    contentData: {
      padding: theme.spacing(3),
    },
    logo: {
      height: '51px',
      width: '279px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logoWrapper: {
      width: '100%',
      height: '64px',
      display: 'flex',
      alignItems: 'center',
    },
    titleBar: {

    },
    buttonsBar: {
      marginLeft: 'auto',
    },
    toolBar: {

    }
  }),
);