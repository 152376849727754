import { FilterInfo, createQueryCallback } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column } from "components/data_table/types";
import DataContext from "odata/context";
import { UserCompany } from "schema";


const columns : Array<Column<UserCompany>> = [
    {
        type: 'string',
        name: 'Наименование',
        field: 'Name',
        sort: true,
    },
    {
        type: 'string',
        name: 'ИНН',
        field: 'Inn',
        sort: true,
    },
    {
        type: 'string',
        name: 'Идентификатор',
        field: 'ExtSystemId',
        sort: true,
    }
];

export const filters: Array<FilterInfo<DataContext, UserCompany>> = [
    {
        type: 'text',
        label: 'Наименование',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Name.includes(value), { value: value })),
    },
    {
        type: 'text',
        label: 'ИНН',
        queryFilter: createQueryCallback((result, value) => result.filter(model => model.Inn.includes(value), { value: value })),
    },
];

export const modifiers : DataModifiersArgs<UserCompany> =
{
    modelName: 'UserCompanies',
    deleteData: {
        text: (model) => `Вы действительно хотите удалить организацию "${model.Name}"?`,
    },
    newData: {
        pages: [
            {
                label: 'Информация',
                columns: [
                    {
                        type: 'string',
                        name: 'Наименование',
                        field: 'Name',
                    },
                    {
                        type: 'string',
                        name: 'ИНН',
                        field: 'Inn',
                    },
                    {
                        type: 'string',
                        name: 'Идентификатор',
                        field: 'ExtSystemId',
                    }
                ]
            }
        ],
        defaultModel: {
            ID: undefined as any,
            UserInfoId: undefined as any,
        }
    },
    updateData: {
        pages: [
            {
                label: 'Информация',
                columns: [
                    {
                        type: 'string',
                        name: 'Наименование',
                        field: 'Name',
                    },
                    {
                        type: 'string',
                        name: 'ИНН',
                        field: 'Inn',
                    },
                    {
                        type: 'string',
                        name: 'Идентификатор',
                        field: 'ExtSystemId',
                    }
                ]
            }
        ],
    },
}

export const defaultModel: UserCompany = {
    ID: undefined as any,
    UserInfoId: undefined as any,
}


export default columns;