import React from 'react';

import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';

import useStyles from './style'
import Form from './form'

// Props:
type LoginPageProps = {
    
};

const LoginPage : React.FunctionComponent<LoginPageProps> = () => {
    const classes = useStyles();

    return  <>
                <Grid container component="main" className={classes.gridContainer}>
                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={7} className={classes.image} />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Form />
                    </Grid>
                </Grid>
            </>
};

export default LoginPage;