import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

// Props:
type BoolFieldProps = {
    label?: string;
    value?: any;
    onChange: (value: any) => any;
};

// Element:
const BoolField : React.FunctionComponent<BoolFieldProps> = ({label, value, onChange}) => {
    const onChangeEventWrapper = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) => {
        switch (event.target.value) {
            case 'true':
                return onChange(true);
            case 'false':
                return onChange(false);
        }
    };

    const getValue = () => {
        if (value === undefined)
        {
            return 'false';
        }

        return value ? 'true' : 'false';
    }

    return (
        <FormControl style={{width: "100%",}}>
            <InputLabel>{label}</InputLabel>
            <Select input={<Input /> } onChange={onChangeEventWrapper} value={getValue()} fullWidth>
                <MenuItem key={"menu_1"} value='true'>Да</MenuItem>
                <MenuItem key={"menu_2"} value='false'>Нет</MenuItem>
            </Select>
        </FormControl>
    );
}

export default BoolField;