import React from 'react';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import * as Constants from 'text_constants/titles'

// Props:
type CopyrightProps = {
    name: string;
    link: string;
};

const Copyright : React.FunctionComponent<CopyrightProps> = ({name, link}) => {
    // ` ${Constants.titlesCopyrightStartYearText} - ${new Date().getFullYear()}.`
    return  <>
                <Typography variant="body2" color="textSecondary" align="center">
                    {Constants.titlesCopyrightText}
                    <Link color="inherit" href={link}>
                        {name}
                    </Link>
                    {` ${new Date().getFullYear()}.`}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                    {Constants.titlesDeveloperText} {" "}
                    <Link color="inherit" href={Constants.titlesDeveloperLink}>
                        {Constants.titlesDeveloperNameText}
                    </Link>
                </Typography>
            </>
}

export default Copyright;