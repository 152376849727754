import { EntitySet, OdataContext } from 'ts2odata';

// Types:

export type RenderTypes = 'text' | 'bool';

export type QueryCallback<TContext extends OdataContext<TContext>, TModel extends object> = (context: TContext) => EntitySet<TModel>;

export type FilterInfo<TContext extends OdataContext<TContext>, TModel extends object> = {
    label: string;
    type: RenderTypes;
    queryFilter: (callback: QueryCallback<TContext, TModel>, value?: any) => QueryCallback<TContext, TModel>;
};

export type FilterState<TContext extends OdataContext<TContext>, TModel extends object> = FilterInfo<TContext, TModel> & {
    value?: any;
};

// Helpers:
export function createQueryCallback<TContext extends OdataContext<TContext>, TModel extends object>(callback: (arg: EntitySet<TModel>, value: any) => EntitySet<TModel>)
    : (callback: QueryCallback<TContext, TModel>, value?: any) => QueryCallback<TContext, TModel> 
{
    return (data, value) => {
        return (context) => {
            let result = data(context);

            if (value !== undefined)
            {
                return callback(result, value);
            }
            else
            {
                return result;
            }
        }
    }
}