import { Button, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { OdataContext } from 'ts2odata';
import { DataFiltersHookResult } from './hook';
import { renderFilter } from './renderFilter';
import useStyles from './styles';
import { FilterState } from './types';

// Props:
type DataFiltersProps<TContext extends OdataContext<TContext>, TModel extends object> = {
    filtersData: DataFiltersHookResult<TContext, TModel>;
};

// Element:
function DataFilters<TContext extends OdataContext<TContext>, TModel extends object>({filtersData} : DataFiltersProps<TContext, TModel>) {
    // Classes:
    const classes = useStyles();

    // States:
    const [filtersStates, setFiltersStates] = React.useState<Array<FilterState<TContext, TModel>>>(filtersData.filters);
    
    // Events:
    const onApply = () => {
        filtersData.setFilters(filtersStates);
    }

    const onClear = () => {
        var result = [...filtersStates];

        result.forEach((filter) => {
            filter.value = undefined;
        });

        setFiltersStates(result);
        filtersData.setFilters(result);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.root}>
                <Typography variant="h6" component="div">
                    Фильтр
                </Typography>
            </div>
            <div className={classes.content}>
                {filtersStates.map((filter, index) => {
                    const onSetValue = (value?: any) => {
                        const outFilters = [...filtersStates];
                        outFilters[index].value = value;
                        setFiltersStates(outFilters);
                    };
                    return (
                        <div className={classes.filter} key={'filter_' + index}>
                            {renderFilter(filter.type, filter.label, onSetValue, filter.value)}
                        </div>
                    )
                })}
            </div>
            <div className={classes.buttons}>
                <Button variant='contained' color='primary' onClick={onApply}>Применить</Button>
                <Button variant='contained' color='primary' onClick={onClear} style={{marginLeft: '10px'}}>Очистить</Button>
            </div>
        </Paper>
    );
}

export default DataFilters;