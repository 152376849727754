import { Button, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import Modal from 'components/modal';
import { useModels } from 'hex/hooks/data';
import DataContext, { useOdataContext } from 'odata/context';
import React from 'react';

// Props:
type ProductFkViewProps = {
    value: any;
    onChange: (value: any) => void;
};

// Element:
const ProductFkView : React.FunctionComponent<ProductFkViewProps> = ({value, onChange}) => {
    // Data Context:
    var context = useOdataContext();

    // States:
    const [open, setOpen] = React.useState<boolean>(false);
    const [category, setCategory] = React.useState<string | null>(null);

    // Queries:
    const categoriesQueryCallback = React.useCallback((context: DataContext) => context.Categories.orderby(x => x.Sort), []);
    const productQueryCallback = React.useCallback((context: DataContext) => {
        if (category !== null)
        {
            return context.Products.filter(x => x.CategoryId === category, {category: `guid'${category}'`});
        }
        else
        {
            return context.Products;
        }
    }, [category]);

    // Models:
    const [countCategories, categories] = useModels(context, categoriesQueryCallback);
    const [countProducts, products] = useModels(context, productQueryCallback);

    if ((categories === null) || (products === null))
    {
        return <div>Loading...</div>
    }

    const renderCategories = () => {
        return categories.map(item =>
            <ListItem color='primary' button key={"cat_" + item.ID} onClick={() => setCategory(item.ID)} disabled={item.ID === category}>
                <ListItemText color='primary' primary={item.Name} />
            </ListItem>
        )
    }

    const renderProducts = () => {
        return products.map(item => 
            <ListItem button key={"prod_" + item.ID} onClick={() => {onChange(item.ID); setOpen(false);}} disabled={item.ID === value}>
                <ListItemText color='primary' primary={item.Name} />
            </ListItem>
        )
    }

    return (
        <>
            <Typography variant='subtitle2'>
                Выбор товара
            </Typography>
            <Button onClick={() => setOpen(true)} variant='outlined' color='primary'>
                Выбор
            </Button>
            <Modal open={open} title='Выбор товара' closeButtonText='Отмена' closeButtonAction={() => setOpen(false)} contentTitle='' maxWidth='lg'>
                <Grid container style={{width: '800px'}}>
                    <Grid item xs={4}>
                        <List>
                            {renderCategories()}
                        </List>
                    </Grid>
                    <Grid item xs={8}>
                        <List>
                            {renderProducts()}
                        </List>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
}

export default ProductFkView;