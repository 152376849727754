import React from 'react';
import {HexAuthContext} from 'hex/providers/index';
import Auth from 'hex/auth';


// Хук для получения настроенного HTTP клиента
export const useAuthHttpClient = () => {
    const context = React.useContext(HexAuthContext);

    if (context.auth === undefined)
    {
        throw new Error( "Не выполнена аутентификация");
    }

    return context.auth.getHttpClient();
}

export const useAuth = () => {
    const context = React.useContext(HexAuthContext);
    return context.auth as Auth;
}

// Получаем статус пользователя: Аутентифицирован, или Нет. Если null, то идет загрузка.
export const useAuthState = () => {
    const context = React.useContext(HexAuthContext);
    return context.signedIn;
}
