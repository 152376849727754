import React from 'react';
import { ActionType, DeleteData, DeleteDataOut, GlobalActionType, ModelType, NewData, NewDataOut, SelectedActionType, UpdateData, UpdateDataOut } from './types';
import AddIcon from '@material-ui/icons/Add';
import ApiUpdate from 'hex/api/update';
import { useAuth } from 'hex/hooks/auth';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ApiDelete from 'hex/api/delete';

export type DataModifiersOut<TModel extends ModelType> = {
    modelName: string;
    deleteData?: DeleteDataOut<TModel>;
    newData?: NewDataOut<TModel>;
    updateData?: UpdateDataOut<TModel>;
    actions: Array<ActionType<TModel>>;
    globalActions: Array<GlobalActionType>;
    selectedActions: Array<SelectedActionType<TModel>>;
    refresh: () => void;
};

export type DataModifiersArgs<TModel extends ModelType> = {
    modelName: string;
    deleteData?: DeleteData<TModel>;
    newData?: NewData<TModel>;
    updateData?: UpdateData<TModel>;
    publishField?: keyof TModel;
};

export type DataModifiersProps<TModel extends ModelType> = DataModifiersArgs<TModel> & {
    refresh: () => void;
};

export function useDataModifiers<TModel extends ModelType>({modelName, deleteData, newData, updateData, refresh, publishField} : DataModifiersProps<TModel>) : DataModifiersOut<TModel> {
    // Auth:
    const auth = useAuth();

    // Shared: ---------------------------------------------------------------------------
    // States:
    const [model, setModel] = React.useState<TModel>();

    // Delete: ---------------------------------------------------------------------------
    // States:
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    // Consts:
    const deleteAction : ActionType<TModel> = React.useMemo(() => {
        return {
            label: 'Удалить',
            enabled: true,
            action: (newModel) => {
                setModel(newModel);
                setDeleteOpen(true);
            }
        };
    }, [setModel, setDeleteOpen]);

    const deleteCloseAction = () => {
        setModel(undefined);
        setDeleteOpen(false);
    }

    // New: -------------------------------------------------------------------------------
    // States:
    const [newOpen, setNewOpen] = React.useState<boolean>(false);
    // Consts:
    const newAction : GlobalActionType = React.useMemo(() => {
        return {
            label: 'Создать',
            icon: (<AddIcon />),
            action: () => {
                setNewOpen(true);
            }
        };
    }, [setNewOpen]);

    const newCloseAction = () => {
        setNewOpen(false);
    }

    // Update: -------------------------------------------------------------------------------
    // States:
    const [updateOpen, setUpdateOpen] = React.useState<boolean>(false);
    // Consts:
    const updateAction : ActionType<TModel> = React.useMemo(() => {
        return {
            label: 'Изменить',
            enabled: true,
            action: (newModel) => {
                setModel(newModel);
                setUpdateOpen(true);
            }
        };
    }, [setModel, setUpdateOpen]);

    const updateCloseAction = () => {
        setModel(undefined);
        setUpdateOpen(false);
    }

    // Publish/Unpublish: --------------------------------------------------------------------
    const helperPublishModel = React.useCallback((model: TModel, publish: boolean) => {
        if ((publishField !== undefined) && ((model[publishField] as any) !== publish))
        {
            const copy = {...model};
            copy[publishField] = publish as any;
            const client = auth.getHttpClient();
            ApiUpdate(client, modelName, copy)
            .then(() => {
                refresh();
            });
        }
    }, [publishField, auth, modelName, refresh]);

    const publishAction : ActionType<TModel> = React.useMemo(() => {
        return {
            label: 'Опубликовать',
            enabled: newModel => !(newModel[publishField as keyof TModel] as any),
            action: newModel => helperPublishModel(newModel, true)
        };
    }, [helperPublishModel, publishField]);

    const unPublishAction : ActionType<TModel> = React.useMemo(() => {
        return {
            label: 'Снять с публикации',
            enabled: newModel => (newModel[publishField as keyof TModel] as any),
            action: newModel => helperPublishModel(newModel, false)
        };
    }, [helperPublishModel, publishField]);

    // Actions:
    const actions = React.useMemo(() => {
        let local : Array<ActionType<TModel>> = [];

        if (updateData)
        {
            local.push(updateAction);
        }

        if (deleteData)
        {
            local.push(deleteAction);
        }

        if (publishField)
        {
            local.push(publishAction);
            local.push(unPublishAction);
        }

        return local;
    }, [deleteData, deleteAction, updateAction, updateData, publishField, publishAction, unPublishAction]);
    
    // Global Actions:
    const globalActions = React.useMemo(() => {
        let local : Array<GlobalActionType> = [];

        if (newData)
        {
            local.push(newAction);
        }

        return local;
    }, [newData, newAction]);

    // Selected Actions: 
    const selectedActions : Array<SelectedActionType<TModel>> = React.useMemo(() => {
        let result : Array<SelectedActionType<TModel>> = [];

        if (publishField)
        {
            result.push({
                label: 'Опубликовать',
                icon: <UnarchiveIcon />,
                action: async (models) => {
                    const client = auth.getHttpClient();

                    for (let index = 0; index < models.length; index++) {
                        const model = models[index];
                        if ((model[publishField] as any) !== true)
                        {
                            const copy = {...model};
                            copy[publishField] = true as any;
                            await ApiUpdate(client, modelName, copy);
                        }
                    }

                    refresh();
                }
            });

            result.push({
                label: 'Снять с публикации',
                icon: <ArchiveIcon />,
                action: async (models) => {
                    const client = auth.getHttpClient();

                    for (let index = 0; index < models.length; index++) {
                        const model = models[index];
                        if ((model[publishField] as any) !== false)
                        {
                            const copy = {...model};
                            copy[publishField] = false as any;
                            await ApiUpdate(client, modelName, copy);
                        }
                    }

                    refresh();
                }
            });
        }

        if (deleteData)
        {
            result.push({
                label: 'Удалить',
                icon: <DeleteIcon />,
                action: async (models) => {
                    const client = auth.getHttpClient();

                    for (let index = 0; index < models.length; index++) {
                        const model = models[index];
                        await ApiDelete(client, modelName, model);
                    }

                    refresh();
                }
            });
        }

        return result;
    }, [auth, deleteData, modelName, refresh, publishField]);

    return {
        modelName: modelName,
        deleteData: deleteData ? {...deleteData, open: deleteOpen, model, closeAction: deleteCloseAction} : undefined,
        newData: newData ? {...newData, open: newOpen, closeAction: newCloseAction} : undefined,
        updateData: updateData ? {...updateData, open: updateOpen, closeAction: updateCloseAction, model} : undefined,
        actions,
        refresh,
        globalActions,
        selectedActions,
    };
}