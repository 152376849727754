import {createMuiTheme} from '@material-ui/core/styles';


export const mainTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#008FC0',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#EAEAEA',
            contrastText: '#333333',
        }
    }
});