import React from 'react';
import { AppBar, Box, Tab, Tabs} from '@material-ui/core';
import Modal from 'components/modal';
import { ModelType, Page, UpdateDataOut } from '../types';
import { renderField } from './fields';
import useStyles from './styles';
import { useAuth } from 'hex/hooks/auth';
import ApiUpdate from 'hex/api/update';

// Props:
type UpdateModalProps<TModel extends ModelType> = UpdateDataOut<TModel> & {
    modelName: string;
    refresh: () => void;
    model: TModel;
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Box>
                {children}
            </Box>
        )}
      </div>
    );
}

// Element:
function UpdateModal<TModel extends ModelType>({open, pages, modelName, closeAction, refresh, model} : UpdateModalProps<TModel>) {
    // Auth:
    const auth = useAuth();
    
    // Classes:
    const classes = useStyles();

    // States:
    const [value, setValue] = React.useState<number>(0);
    const [localModel, setModel] = React.useState<TModel>(model);

    // Events:
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const updateAction = () => {
        const client = auth.getHttpClient();
        ApiUpdate(client, modelName, localModel)
        .then(() => {
            refresh();
            closeAction();
        });
    }

    // Renders:
    const renderPage = (page: Page<TModel>) => {
        return (
            <div className={classes.content}>
                {page.columns.map((column, index) => {
                    return (
                        <div className={classes.field} key={'field_' + index}>
                            {renderField(column, localModel, setModel)}
                        </div>
                    );
                })}
            </div>
        )
    }

    const renderTabs = () => {
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange}>
                        {pages.map((page, index) => (<Tab label={page.label} key={'tab_' + index} />))}
                    </Tabs>
                </AppBar>
                {pages.map((page, index) => {
                    return (
                        <TabPanel value={value} index={index} key={'tab_' + index}>
                            {renderPage(page)}
                        </TabPanel>
                    );
                })}
            </div>
        )
    }

    if (open === false)
    {
        return null;
    }

    return (
        <Modal  open={open} title='Изменение объекта' contentTitle='' 
                closeButtonText='Отмена' closeButtonAction={closeAction}
                actionButtonText='Изменить' actionButtonAction={updateAction}
                maxWidth='xl'>
            {pages.length === 1 ? renderPage(pages[0]) : renderTabs()}
        </Modal>
    );
}

export default UpdateModal;