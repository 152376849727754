import { DataModifiersOut } from "components/data_modifiers/hooks";
import React from "react";
import { DataViewerInfo, DataViewerModelInfo, ModelType } from "./types";

export const useViewer = <TModel extends ModelType>(data: DataViewerModelInfo<TModel>, modifiers: DataModifiersOut<TModel>) : [DataViewerInfo<TModel>, DataModifiersOut<TModel>] => {
    const [model, setModel] = React.useState<TModel>();
    const [open, setOpen] = React.useState<boolean>(false);

    const outModifiers = React.useMemo(() => {
        modifiers.actions.push({
            enabled: true,
            label: "Просмотр",
            action: (actModel) => {
                setModel(actModel);
                setOpen(true);
            }
        });

        return modifiers;
    }, [modifiers.actions, setModel, setOpen]);

    return [{
        componentToRender: data.componentToRender,
        closeAction: () => {
            setOpen(false);
            setModel(undefined);
        },
        open,
        model
    }, outModifiers];
}