import { FilterInfo } from "components/data_filters/types";
import { DataModifiersArgs } from "components/data_modifiers/hooks";
import { Column as DataColumns } from "components/data_modifiers/types";
import DataContext from "odata/context";
import { ProductPrices, SectionPrices } from "schema";

import SectionPriceColumn from './sectionPrices';

const columns : Array<DataColumns<ProductPrices, SectionPrices>> = [
    {
        type: 'related_readonly_field',
        name: 'Наименование',
        field: 'SectionPrice',
        column: SectionPriceColumn[0],
        relatedField: 'Name',
    },
    {
        type: 'related_readonly_field',
        name: 'Пороговое значение',
        field: 'SectionPrice',
        column: SectionPriceColumn[1],
        relatedField: 'Threshold',
    },
    {
        type: 'decimal',
        name: 'Цена',
        field: 'Value',
    },
    {
        type: 'readonly_datetime',
        name: 'Дата изменения',
        field: 'Updated',
    },
    {
        type: 'readonly_datetime',
        name: 'Дата создания',
        field: 'Created',
    },
];

export const filters: Array<FilterInfo<DataContext, ProductPrices>> = [];

export const defaultModel: ProductPrices = {
    ID: undefined as any,
    ProductId: undefined as any,
    SectionPriceId: undefined as any,
    Value: 0,
};

export const modifiers : DataModifiersArgs<ProductPrices> = {
    modelName: 'ProductPrices',
    deleteData: {
        text: (model) => `Вы действительно хотите удалить цену?`,
    },
    newData: {
        pages: [
            {
                label: 'Информация о ценах',
                columns: [
                ],
            },
        ],
        defaultModel: {
            ID: undefined as any,
            ProductId: undefined as any,
            SectionPriceId: undefined as any,
            Value: 0,
        }
    },
}

export default columns;