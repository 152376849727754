import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import {ModelType, Order} from './types';


export interface HeadCell<TModel extends ModelType> {
    id: keyof TModel;
    label: string;
    sort?: boolean;
}

interface EnhancedTableProps<TModel extends ModelType> {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TModel) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: keyof TModel;
    rowCount: number;
    headCells: Array<HeadCell<TModel>>;
    actions: boolean;
}

export default function EnhancedTableHead<TModel extends ModelType>(props: EnhancedTableProps<TModel>) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, actions } = props;

    const createSortHandler = (property: keyof TModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox color='primary' indeterminate={numSelected > 0 && numSelected < rowCount} checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} />
                </TableCell>
                {actions && 
                    <TableCell>
                        
                    </TableCell>
                }
                {headCells.map((headCell, index) => {
                    if (headCell.sort === true)
                    {
                        return (
                            <TableCell key={"headcell_" + index} padding='default' sortDirection={orderBy === headCell.id ? order : false} >
                                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        );
                    }
                    else
                    {
                        return (
                            <TableCell key={"headcell_" + index} padding='default'>
                                {headCell.label}
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </TableHead>
    );
}