import { Column, ModelType } from "components/data_modifiers/types";
import React from "react";
import BigText from "./bigtext";
import BoolField from "./bool";
import ImageField from "./image";
import NumberField from "./number";
import ReverseFk from "./reverse_fk";
import SlugField from "./slug";
import TextFieldData from "./text";
import HTMLTextField from './htmltext';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import LookupField from "./lookup";
import DecimalField from "./decimal";
import ForeignKeyField from "./fk";

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function renderField<TModel extends ModelType>(column: Column<TModel>, model: TModel, setModel: React.Dispatch<React.SetStateAction<TModel>>) : any {
    const defaultSetValue = (value: any) => {
        setModel(oldModel => {
            var copy = {...oldModel};

            copy[column.field] = value;

            return copy;
        });
    }

    switch (column.type) {
        case 'string':
            return <TextFieldData label={column.name} value={model[column.field]} onChange={defaultSetValue} />
        case 'bigtext':
            return <BigText label={column.name} value={model[column.field]} onChange={defaultSetValue} />
        case 'htmltext':
            return <HTMLTextField label={column.name} value={model[column.field]} onChange={defaultSetValue} />
        case 'slug':
            return <SlugField label={column.name} value={model[column.field]} onChange={defaultSetValue} relatedValue={column.related ? model[column.related] : undefined} />
        case 'bool':
            return <BoolField label={column.name} value={model[column.field]} onChange={defaultSetValue} />
        case 'number':
            return <NumberField label={column.name} value={model[column.field]} onChange={defaultSetValue} />
        case 'decimal':
            return <DecimalField label={column.name} value={model[column.field]} onChange={defaultSetValue} />
        case 'image':
            return <ImageField label={column.name} value={model[column.field]} onChange={defaultSetValue} />
        case 'reverse_fk':
            return <ReverseFk label={column.name} value={model[column.field]} onChange={defaultSetValue} columns={column.columns} defaultModel={column.defaultModel} beforeCreate={column.beforeCreate} initialModels={column.initialModels} filterModels={column.filterModels} />
        case 'medias':
            return <ReverseFk label={column.name} value={model[column.field]} onChange={defaultSetValue} columns={column.columns} defaultModel={column.defaultModel} />
        case 'related_readonly_field':
            return (model[column.field] as any)[column.relatedField];
        case 'readonly_datetime':
            let val = (model[column.field] as any);
            return val ? moment.utc(val).tz(clientTimeZone).format("DD-MM-YYYY HH:mm") : '-';
        case 'lookup':
            return <LookupField label={column.name} value={model[column.field]} onChange={defaultSetValue} lookup={column.lookup}/>
        case 'fk':
            return <ForeignKeyField label={column.name} value={model[column.field]} onChange={defaultSetValue} getName={column.getName} query={column.query} />
        case 'fk-custom-view':
            return column.view({value: model[column.field], onChange: defaultSetValue});
        case 'related_field':
            return renderField(column.column, model[column.field] as any, (arg: (any | ((prevState: any) => any))) => {
                if (typeof arg === 'function')
                {
                    defaultSetValue(arg(model[column.field]));
                }
                else
                {
                    defaultSetValue(arg);
                }
            });
        default:
            break;
    }

    return (
        <></>
    );
}