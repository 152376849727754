import React from 'react';
import { OdataContext } from 'ts2odata';
import { FilterState, QueryCallback, FilterInfo } from './types';

export type DataFiltersHookResult<TContext extends OdataContext<TContext>, TModel extends object> = {
    filters: Array<FilterState<TContext, TModel>>;
    setFilters: React.Dispatch<React.SetStateAction<FilterState<TContext, TModel>[]>>;
    resultQuery: QueryCallback<TContext, TModel>;
}

export function useDataFilters<TContext extends OdataContext<TContext>, TModel extends object>(filters: Array<FilterInfo<TContext, TModel>>, getQuery: QueryCallback<TContext, TModel>) 
    : DataFiltersHookResult<TContext, TModel>
{
    // States:
    const [filtersStates, setFiltersStates] = React.useState<Array<FilterState<TContext, TModel>>>(filters);

    // Helpers:
    const getNewQuery = React.useCallback(() : QueryCallback<TContext, TModel> => {
        var result = getQuery;

        filtersStates.forEach((filter) => {
            if (filter.value !== undefined)
            {
                result = filter.queryFilter(result, filter.value);
            }
        });

        return result;
    }, [getQuery, filtersStates]);

    const resultQuery = React.useMemo(getNewQuery, [getNewQuery]);

    return {
        filters: filtersStates,
        setFilters: setFiltersStates,
        resultQuery
    };
}