import HttpClient from "hex/http/client";
import { processHttpError } from "hex/utils/errors";

type ModelType = {
    ID: string;
}

const ApiUpdate = (client: HttpClient, modelName: string, model: ModelType) => {
    return new Promise((resolve, reject) => {
        client.put(`/api/models/${modelName}(${model.ID})`, model)
        .then((data) => {
            resolve(data);
        })
        .catch((reason) => {
            processHttpError(reason);
            reject(reason);
        });
    });
}

export default ApiUpdate;