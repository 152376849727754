import React from 'react';
import { useDataFilters } from 'components/data_filters/hook';
import { usePagedModels } from 'hex/hooks/data';
import { useDataModifiers } from 'components/data_modifiers/hooks';
import { useOrder } from 'hex/hooks/models';
import columns, { filters, modifiers } from 'model_data/categories';
import DataContext, { useOdataContext } from 'odata/context';
import { useHistory, useParams } from 'react-router-dom';
import DataFilters from 'components/data_filters';
import DataTable from 'components/data_table';
import DataModifiers from 'components/data_modifiers';
import { IconButton } from '@material-ui/core';
import { ArrowBackIosOutlined } from '@material-ui/icons';

// Props:
type CategoriesPageProps = {

};

// Element:
const CategoriesPage : React.FunctionComponent<CategoriesPageProps> = () => {
    // RouteData:
    const { section } = useParams<{section: string}>();
    
     // Data Context:
     var context = useOdataContext();

     // Query:
     const queryCallback = React.useCallback((context: DataContext) => context.Categories.filter(i => i.SectionId === section, {section: `guid'${section}'`}), [section]);
     
     // Order:
     const orderData = useOrder(columns[0].field);
 
     // Filters:
     const filtersData = useDataFilters(filters, queryCallback);
 
     // Models:
     const [, sections, pagination, refresh] = usePagedModels(context, filtersData.resultQuery, orderData);
 
     // Modifiers:
     const modifiersData = useDataModifiers({...modifiers(section), refresh});
 
    // History:
    const history = useHistory();

    // Loading:
    if (sections === null)
    {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }
    
    return (
        <>
            <div style={{display: 'flex', }}>
                <IconButton onClick={() => history.push('/')}>
                    <ArrowBackIosOutlined/>
                </IconButton>
                <h1>Категории раздела</h1>
            </div>
 
             <DataFilters filtersData={filtersData} />
             <DataTable models={sections} columns={columns} orderData={orderData} pagination={pagination}
                        actions={modifiersData.actions} globalActions={modifiersData.globalActions} selectedActions={modifiersData.selectedActions} />
             <DataModifiers modifiersData={modifiersData} />
         </>
     );
}

export default CategoriesPage;