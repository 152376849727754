import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import useStyles from './styles';


// Props:
type ModalProps = {
    open: boolean;
    title: string;
    contentTitle: string;
    closeButtonText: string;
    closeButtonAction: () => void;
    actionButtonText?: string;
    actionButtonAction?: () => void;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    fullScreen?: boolean;
    customBackground?: boolean;
};


const Modal : React.FunctionComponent<ModalProps> = ({open, fullScreen, title, customBackground, contentTitle, closeButtonText, closeButtonAction, actionButtonText, actionButtonAction, children, maxWidth}) => 
{
    const classes = useStyles();


    return (
        <Dialog open={open} onClose={closeButtonAction} maxWidth={maxWidth} fullScreen={fullScreen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={customBackground ? classes.content : undefined}>
                <DialogContentText>{contentTitle}</DialogContentText>
                {children}
                <Divider />
            </DialogContent>
            <DialogActions>
                {actionButtonText && actionButtonAction && 
                    <Button variant="contained" onClick={actionButtonAction} color="primary">{actionButtonText}</Button>
                }
                <Button variant="contained" onClick={closeButtonAction} color="secondary">{closeButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Modal;